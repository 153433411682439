.modal-wrapper,
.modal-wrapper.sc-ion-modal-md {
  width: 34.3125rem;
  max-height: 96%;
  border-radius: 15px;
  box-shadow: none;
}

ion-modal {
  @media (max-width:961px)  {
    .modal-wrapper {
        border-radius: initial!important;;
        max-height: initial!important;
    }
  }
  &.FileFormatWarning {
    --width: 650px;
    --height: 250px;

    .CardAlert__text{
      text-align: center;
    }
  }
  &.CameraNotFound {
    --width: 650px;
    --height: 350px;
  }
  &.autoHeight {
    --width: 650px;
    --min-height: 300px;
    --height: auto;
  }
  &.PaymentReceipt {
    --width: 650px;
    --height: 500px;
    @media (min-width: 1280px) {
      --width: 650px;
      --height: 500px;
    }
  }
  &.ComingSoon {
    --width: 520px;
    --height: 153px;
    @media (min-width: 1280px) {
      --width: 520px;
      --height: 153px;
    }
  }
  &.PrintStatementPreview {
    --width: 520px;
    --height: 620px;
    @media (min-width: 1280px) {
      --width: 520px;
      --height: 620px;
    }
  }
  &.TopUp {
    --width: 473px;
    --height: 473px;
    @media (min-width: 1280px) {
      --width: 650px;
      --height: 600px;
      //height: 688px;
    }
  }
  &.TopUpErrModal {
    --width: 473px;
    --height: 473px;
    --border-radius: 16px;
    @media (min-width: 1280px) {
      --width: 650px;
      --height: 600px;
    }
  }
  &.TopUpInactive {
    --width: 520px;
    --height: 295px;
    @media (min-width: 1280px) {
      --width: 520px;
      --height: 295px;
    }
  }
  &.BinanceRemove {
    --width: 410px;
    --height: auto;
    @media (min-width: 1280px) {
      --width: 650px;
      --height: auto;
    }
  }
  &.TopUpSuccess {
    --width: 473px;
    --height: 473px;
    @media (min-width: 1280px) {
      --width: 514px;
      --height: 599px;
    }
  }
  &.TopUpFail {
    --width: 650px;
    --height: 318px;
    @media (min-width: 1280px) {
      --width: 650px;
      --height: 318px;
    }
  }
  &.SelectCountry {
    --width: 100%;
    --height: 100vh;
    @media (min-width: 576px) {
      --width: 520px;
      --height: 546px;
      --border-radius: 16px;
    }
    ion-backdrop { opacity: 1!important; }
  }
  &.CardBlock {
    --width: 650px;
    --height: 328px;
    @media (min-width: 1280px) {
      --width: 650px;
      --height: 328px;
    }
  }
  &.CardUnblock {
    --width: 650px;
    --height: 318px;
    @media (min-width: 1280px) {
      --width: 650px;
      --height: 318px;
    }
  }
  &.CardDetail {
    --width: 286px;
    --height: 281px;

    @media (min-width: 1280px) {
      --width: 650px;
      --height: 318px;
    }
  }
  &.CardData {
    --width: 374px;
    --height: 303px;

    @media (min-width: 1280px) {
      --width: 23rem;
      --height: 23.375rem;
    }
  }
  &.CardLimitDisable {
    --width: 650px;
    --height: 318px;
    @media (min-width: 1280px) {
      --width: 650px;
      --height: 318px;
    }
  }
  &.CardDayLimits,
  &.CardMonthLimits {
    // width: 650px;
    // height: 318px;
    // @media (min-width: 1280px) {
    //   width: 650px;
    //   height: 280px;
    // }
    --width: 480px;
    --height: 218px;
    @media (min-width: 1280px) {
      --width: 32.5rem;
      --height: 260px;
    }
  }
  &.CardLimitsConfirmation {
    // width: 650px;
    // height: 353px;
    // @media (min-width: 1280px) {
    //   width: 650px;
    //   height: 353px;
    // }
    --width: 480px;
    --height: 271px;
    @media (min-width: 1280px) {
      --width: 32.5rem;
      --height: 20.8125rem;
    }
  }
  &.InterestRateConfirm {
    --height: 18.5rem;
    --overflow: visible;
    .ion-page {
      overflow: visible;
    }
  }
  &.TransferConfirmationModal {
    --width: 480px;
    --height: 271px;
    @media (min-width: 1280px) {
      --width: 32.5rem;
      --height: 20.8125rem;
    }

    &.TransferToContact {
      --height: 287px;
    }
  }
  &.GekkoinAttachWallet {
    --width: 550px;
    --height: 500px;
    @media (min-width: 1280px) {
      --width: 32.5rem;
      --height: 37.8125rem;
    }
  }
  &.TemplateEnterNameModal {
    --width: 480px;
    --height: 271px;
    @media (min-width: 1280px) {
      --width: 650px;
      --height: 280px;
    }
  }
  &.SendMoneyRequestReceive {
    --width: 480px;
    --height: 248px;
    @media (min-width: 1280px) {
      --width: 520px;
      --height: 316px;
    }
  }
  &.SendMoneyRequestPayment {
    --width: 480px;
    --height: 474px;
    @media (min-width: 1280px) {
      --width: 32.125rem;
      --height: 39.8125rem;
    }
  }
  &.SendMoneyRequestConfirmation {
    --width: 480px;
    --height: 474px;
    @media (min-width: 1280px) {
      --width: 514px;
      --height: 562px;
    }
  }
  &.SendMoneyRequestBanner {
    --width: 520px;
    --height: 358px;
    @media (min-width: 1280px) {
      --width: 650px;
      --height: 372px;
    }
  }

  &.PersonalInfoEdit {
    --width: 700px;
    --height: 432px;
    @media (min-width: 1280px) {
      --width: 33.5rem;
      --height: 29.5rem;
    }
  }
  &.SettingsIdentityStatus {
    --width: 500px;
    --height: 300px;
    @media (min-width: 1280px) {
      --width: 560px;
      --height: 360px;
    }
  }
  &.SettingsChangePassword {
    --width: 520px;
    --height: 438px;
    @media (min-width: 1280px) {
      --width: 520px;
      --height: 438px;
    }
  }
  &.SettingsLogOut {
    --width: 480px;
    --height: 255px;
    @media (min-width: 1280px) {
      --width: 32.5rem;
      --height: 18.8125rem;
    }
  }
  &.SettingsMyTariff {
    --width: 700px;
    --height: 472px;
    @media (min-width: 1280px) {
      --width: 999px;
      --height: 603px;
    }
  }
  &.ProtectionPolicy,
  &.TermsAndConditions,
  &.ThirdPartySoftwareLibraries {
    --width: 700px;
    --height: 472px;
    @media (min-width: 1280px) {
      --width: 999px;
      --height: 610px;
    }
  }
  &.InterestRateInsFunds {
    --height: 14.3125rem;
  }
  &.InterestRateStopUsing {
    --height: 13.875rem;
  }
  &.RegistrationTerms {
    --width: 700px;
    --height: 473px;
    @media (min-width: 1280px) {
      --width: 999px;
      --height: 527px;
    }
  }
  &.OnOffCryptoCurrency {
    --width: 479px;
    --height: 403px;
    @media (min-width: 1280px) {
      --width: 514px;
      --height: 459px;
    }
  }
  &.EditAddress {
    --width: 479px;
    --height: 473px;
    @media (min-width: 1280px) {
      --width: 524px;
      --height: 637px;
    }
  }
  &.StopCashback {
    --width: 650px;
    --height: 234px;
    @media (min-width: 1280px) {
      --width: 650px;
      --height: 234px;
    }
  }
  &.InsufficientFunds {
    --width: 650px;
    --height: 200px;
    @media (min-width: 1280px) {
      --width: 650px;
      --height: 200px;
    }
  }
  &.ConfirmCashback {
    --width: 650px;
    --height: 353px;
    @media (min-width: 1280px) {
      --width: 650px;
      --height: 353px;
    }
  }
  &.SuccessCashback {
    --width: 650px;
    --height: 500px;
    @media (min-width: 1280px) {
      --width: 650px;
      --height: 500px;
    }
  }


  &.ModalConfirmation {

    --background-color: transparent;

    .ion-page {
      --padding-bottom: 53px;
      --background-color: transparent;
    }
    .ModalConfirmation {
      &__window {
        --border-radius: 1rem;
        --background-color: var(--ion-color-white);
      }

      &__resend {
        position: absolute;
        bottom: 0; left: 0; right: 0;
        z-index: 10000;

        ion-button {
          display: inline-block;
          width: 165px;
          height: 30px;
          --height: 30px;
          line-height: 1.75rem;
          font-size: 0.8125rem;
          font-weight: 400;
          color: var(--ion-color-white);
          --border-color: var(--ion-color-white);
          @media (min-width: 1280px) {
            width: 202px;
          }
        }
      }
    }

    @media (min-width: 1280px) {
      .ion-page { padding-bottom: 73px; }
    }
  }

  .Modal,
  .ion-page {
    --width: 100%; --height: 100%;
    --border-radius: 1rem;
    --background-color: var(--ion-color-white);
  }


  &.ModalFullScreen {
    ion-backdrop {
      opacity: 0;
    }
    --width: 100%;
    --height: 100%;
    --max-height: 100%;
    --border-radius: 0;

    .ion-page {
      --border-radius: 0;
    }
  }
}
.ConfirmDialog {
  --height: 65%
}
@media (min-width: 600px) {
  .ConfirmDialog {
    --height: 250px
  }
}

