.loaging {
  width: 1rem; height: 1rem;
  display: inline-block;
  vertical-align: middle;

  svg {
    width: 100%; height: 100%;

    path {
      fill: var(--ion-color-white);

      animation: lds-spinner 1.1s linear infinite;

      &:nth-child(1)  { animation-delay: 0.0s; }
      &:nth-child(2)  { animation-delay: 0.1s; }
      &:nth-child(3)  { animation-delay: 0.2s; }
      &:nth-child(4)  { animation-delay: 0.3s; }
      &:nth-child(5)  { animation-delay: 0.4s; }
      &:nth-child(6)  { animation-delay: 0.5s; }
      &:nth-child(7)  { animation-delay: 0.6s; }
      &:nth-child(8)  { animation-delay: 0.7s; }
      &:nth-child(9)  { animation-delay: 0.8s; }
      &:nth-child(10) { animation-delay: 0.9s; }
      &:nth-child(11) { animation-delay: 1.0s; }
      &:nth-child(12) { animation-delay: 1.1s; }
    }
  }
}
@keyframes lds-spinner {
  0%   { opacity: 1.00; }
  9%   { opacity: 0.95; }
  18%  { opacity: 0.90; }
  27%  { opacity: 0.80; }
  36%  { opacity: 0.60; }
  45%  { opacity: 0.55; }
  54%  { opacity: 0.50; }
  63%  { opacity: 0.45; }
  72%  { opacity: 0.40; }
  81%  { opacity: 0.30; }
  90%  { opacity: 0.25; }
  100% { opacity: 0.20; }
}