.main-app {
  display: flex;
  // Need this change for bank-transfer-mobile view
  //align-items: center;
  justify-content: flex-start;
  overflow: auto;

  > ion-split-pane {
    position: static;
    // Need this change for bank-transfer-mobile view
    width: 100%;
    min-width: 1200px;
    //max-width: 90rem;
    // min-height: 43.75rem;
    height: 100%;
    max-height: 100%;
  }

  // TODO need refactor bank-transfer-mobile
  // Need this change for bank-transfer-mobile view
  @media (min-width: 1024px) {
    align-items: center;
  }
  // Need this change for bank-transfer-mobile view 
  @media (max-width:961px)  {
    > ion-split-pane {
      min-width: initial;
      max-width: initial;
    }
  }

  @media (min-width: 1920px) {
    > ion-split-pane {
      width: 1680px;
    }
  }
}

ion-list,
.list-md,
.list-ios {
  padding: 0;
  background: transparent;
}


.header,
ion-header {
  text-align: center;
  border-bottom: 1px solid var(--ion-color-border);

  &:after {
    display: none;
  }

  ion-toolbar {
    --background: var(--ion-color-white);
  }
  
  ion-title {
    font-size: 1rem;
    font-weight: 600;
    letter-spacing: -0.4px;
    color: var(--ion-color-black);
  }
}


ion-toolbar {
  --padding-top: 0;
  --padding-bottom: 0;
  --padding-end: 0;
  --padding-start: 0;
  --min-height: 3.4375rem;
}
ion-router-outlet[name="rightBox"] {
  ion-toolbar {
    --min-height: 3.4375rem;
  }
}

//.item {
//  --highlight-color-focused: transparent;
//}

.page {
  height: auto;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__content {
  }
  &__bottom {
  }

  &-list {
    
    margin: 0 0 2rem;
    background-color: transparent;
    border-radius: 0.3125rem;
    overflow: hidden;
    // border: 0.5px solid var(--ion-color-border);

    ion-item,
    .item {
      --min-height: 37.5px;
      --border-width: 0.5px;
      --inner-padding-end: 16px;

      margin: 0 0 -1px;
      font-weight: 400;
      font-size: 0.8125rem;
      background-color: var(--ion-color-white);

      .item-has-focus { outline: none; }

      &:last-child {
        margin: 0;
        --border-radius: 0 0 0.3125rem 0.3125rem;
        &:first-child { --border-radius: 0.3125rem; }
      }
      &:first-child {
        --border-radius: 0.3125rem 0.3125rem 0 0;
        &:last-child { --border-radius: 0.3125rem; }
      }

      &.item-comment {
        ion-label {
          //max-width: 180px;
          margin-bottom: 15px;
        }
      }

      ion-label {
        max-width: 50%;
        margin-top: 0;
        margin-bottom: 0;
        color: var(--ion-color-black);
      }
      &.item-select {
        ion-label {
          max-width: none;
          flex: 1;
        }
      }
      textarea,
      ion-input,
      ion-select {
        width: 100%;
        min-width: 100%;
        flex: auto;
        text-align: right;
        white-space: normal;
        padding-left: 0;
        padding-top: 0;
        padding-bottom: 0;
      }
      textarea {
        min-height: 15px;
        height: 30px;
        line-height: 15px;
        border: none;
        margin: 13px 0;
        resize: none;
        &:hover, &:focus, &:active { outline: none; }
        &::-moz-placeholder {
          line-height:15px;
          color: rgba($color: #000000, $alpha: 0.33);
        }
        &::-webkit-input-placeholder {
          line-height:15px;
          color: rgba($color: #000000, $alpha: 0.33);
        }
        &:-ms-input-placeholder {
          line-height:15px;
          color: rgba($color: #000000, $alpha: 0.33);
        }
      }
      ion-select {
        position: static;
        height: 100%;
        display: flex;
        align-items: center;
        margin-right: -8px;
        line-height: 1.1875rem;
        --min-width: 100%;

        //&:after { right: 15px; top: 1px; }
        &:after { width: 14px; top: 1px; }

        &[interface="popover"] {
          min-width: 199px;
          max-width: 199px;
        }
      }
      ion-toggle {
        margin-right: 10px;
      }

      &.error {
        position: relative;
        overflow: visible;
        --border-color: var(--ion-color-danger);
        --border-width: 0.5px;

        & + .item {
          &:not(.error) {
            --border-width: 0 0.5px;
          }
          &.error,
          &:last-child {
            --border-width: 0 0.5px 0.5px;
          }
        }

        ion-input,
        ion-select {
          color: var(--ion-color-danger);
          --placeholder-color: var(--ion-color-danger);
        }
      }

      &.item-disabled {
        opacity: .5;
      }

      @media (min-width: 1280px) {
        --min-height: 47.5px;
        ion-select { 
          //margin-right: 0.5rem;
          margin-right: -8px;
          //--padding-end: 21px;
          //&:after { right: 14px; top: 0; } 
          &[interface="popover"] {
            min-width: 222px;
            max-width: 222px;
          }
        }
      }
      @media (min-width: 1440px) {
        --min-height: 47.1px;
        //--inner-padding-end: 10px;
        --inner-padding-end: 16px;
      }
    }

    &__value {
      margin-top: 9px;
      margin-bottom: 9px;
      color: var(--ion-color-dark);
      text-align: right;
      letter-spacing: -0.1px;

      @media (min-width: 1280px) {
        margin-top: 0.75rem;
        margin-bottom: 0.75rem;
      }
    }
  }
  &-field {
    --min-height: auto;
    --inner-padding-end: 16px;
    margin-bottom: 6px;
    letter-spacing: 0.1px;
    @media (min-width: 1280px) {}
  }
  &-total {
    margin-bottom: 2px;
    font-size: 11px;
    letter-spacing: 0.1px;
    // color: var(--ion-color-medium);
    opacity: .33;
    small { font-size: 9px; }
    &__value { letter-spacing: 0.1px; }

    @media (min-width: 1280px) {
      margin-bottom: 9px;
    }
  }
  &-balance {
    font-size: 13px;
    letter-spacing: -0.1px;
    color: var(--ion-color-black);
    small { font-size: 11px; }
    &__value { color: var(--ion-color-success); letter-spacing: 0.2px; }
  }
  &-group {
    --min-height: 52px;
    --padding-start: 14px;
    // --inner-padding-end: 0.375rem;
    margin-top: 7px;
    font-weight: 600;
    font-size: 0.8125rem;
    border-radius: 0.3125rem;
    border: 2px solid var(--ion-color-success);
    --show-full-highlight: 0;
    --background-hover: transparent;

    small { font-size: 0.6875rem; }

    &__value {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      ion-input {
        position: relative;
        z-index: 2;
        text-align: right;
        --padding-end: 0;
      }
      span,
      ion-select {
        z-index: 0;
        --padding-top: 0;
        --padding-start: 0;
        --padding-bottom: 0;
        --padding-end: 8px;
        line-height: 19px;
        font-size: 0.6875rem;
        margin-left: 3px;
        margin-top: 1px;

        &:after { width: 20px; }
      }
    }

    &.error {
      .page-group__value {
        color: var(--ion-color-danger); 
      }
    }

    @media (min-width: 1280px) {
      --min-height: 52px;
      &__value {
        ion-select {
          --padding-end: 8px;
          &:after { width: 20px; }
        }
      }
    }
  }
  &-next {
    margin-top: 30px;
    text-align: center;

    .button {
      width: 175px; height: 32px;
      margin-right: 15px;
      display: inline-block;
      ion-icon {
        left: -30px;
      }
    }

    @media (min-width: 1280px) {
      margin-top: 30px;
      .button {
        width: 212px; height: 40px;
        margin-right: 15px;
        //ion-icon { left: 16px; }
      }
    }
  }
}

.customScrollbar {
    height: 100%;
    overflow-y: auto;
}
::-webkit-scrollbar-track,
.customScrollbar::-webkit-scrollbar-track {
  border-radius: 3px;
  background-color: transparent;
}
::-webkit-scrollbar,
.customScrollbar::-webkit-scrollbar {
  width: 4px;
  background-color: transparent;
}
::-webkit-scrollbar-thumb,
.customScrollbar::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: #dddddd;
}

app-support-chat .dark .Chat__bottom {
  border-radius: 15px 15px 0 0;
}
