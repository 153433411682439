.manualErrors {
  ion-item,
  .item {
    &.ion-invalid {
      &.ion-pristine {
        --background: transparent;
        --highlight-background: transparent;
        --full-highlight-height: 0;
      }

      &.ion-dirty {
        --full-highlight-height: 2px;
        --highlight-background: var(--ion-color-danger);
      }
    }
  }

  &.displayErrors {
    ion-item,
    .item {
      &.ion-invalid {
        --full-highlight-height: 2px;
        --highlight-background: var(--ion-color-danger);

        &.field-group,
        &.transfer-amount {
          --full-highlight-height: 0;
          --highlight-background: transparent;
          border-color: var(--ion-color-danger);
        }
      }
    }

    .page-list {
      ion-item,
      .item {
        &.error,
        &.ion-invalid {
          z-index: 2;
          --highlight-height: 2px;
          --highlight-background: transparent;
          --border-color: var(--ion-color-danger);
        }
      }
    }
  }
}

ion-item-divider.error-divider {
  border-bottom: unset;
}

ion-item.control-invalid,
.item.control-invalid {
  --highlight-height: 2px;
  --highlight-background: transparent;
  --border-color: var(--ion-color-danger);
  border-bottom: 1px solid red;
}

ion-item.control-comment-invalid,
.item.control-comment-invalid {
  --highlight-height: 2px;
  --highlight-background: transparent;
  --border-color: var(--ion-color-danger);
}

.error-item {
  font-size: 12px;
}

.error-block {
  padding-top: 5px;
  min-height: 20px;
  font-size: 12px;
  text-align: right;
}
