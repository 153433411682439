/*
.Authorization {
	&.Bcc {
		background-color: var(--ion-color-black);
		background-image: url(../assets/imgs/cat-bg@2x.png);
		background-size: 300px auto;
		background-repeat: no-repeat;
		background-position: center 60px;

		@media(min-width: 1200px) {
			background-size: 400px auto;
			background-position: center 60px;
			// padding: 180px 20px 20px;
		}
		@media(min-width: 1440px) {
			background-position: center 40px;
			// padding: 192px 20px 20px;
		}
	}

	&.Gekkard {
		background-color: rgb(31, 30, 96);
		background-image: url(../assets/gekkard/gekkard-logo@2x.png);
		background-size: 300px auto;
		background-repeat: no-repeat;
		background-position: center 200px;
	}

	color: var(--ion-color-white);
	align-items: center;
	justify-content: center;
	padding: 162px 20px 20px;

	ion-select:after {
		background-color: var(--ion-color-black);
		background-image: url(../assets/imgs/arrow-down-small-white.svg);
	}

	&-container {
		width: 250px;
		@media(min-width: 1200px) {
			width: 270px;
		}
	}

	&-logo {
		 margin-bottom: 33px;
		 svg { width: 200px; height: auto; }

		 @media(min-width: 1200px) {
			 margin-bottom: 35px;
			 svg { width: 275px; height: auto; }
		 }
		 @media(min-width: 1440px) {
			 margin-bottom: 50px;
		 }
	 }

	&-form {

		.error-label {
			position: absolute;
			left: 60px; top: 4px;
			color: var(--ion-color-danger);
			display: none;
			font-size: 13px;
			line-height: 15px;
		}

		&__row {
			position: relative;
			width: 100%;
			min-height: 63px;
			padding: 4px 0 14px;
			border-bottom: 1px solid rgba(255, 255, 255, 0.5);
			& + .Authorization-form__row { margin-top: 10px; }

			@media(min-width: 1280px) {
				& + .Authorization-form__row { margin-top: 15px; }
			}
		
			ion-label {
				display: block;
				margin: 0 0 8px;
				font-size: 13px;
				line-height: 15px;
				color: rgba(255, 255, 255, 0.5);
			}
			input,
			ion-input {
				--padding-end: 0;
				--padding-start: 0;
				height: 22px;
				line-height: 22px;
				font-size: 15px;
				font-weight: 400;
				background: transparent;
				border: none;
				padding-left: 15px;

				&[name="code"],
				&[name="password"] {
					color: rgba(255, 255, 255, 0.5);
				}
				&[type="password"] { padding: 0; }

				&:focus { outline: none; }
			}
			!*input:-webkit-autofill,
			input:-webkit-autofill:hover, 
			input:-webkit-autofill:focus, 
			input:-webkit-autofill:active  {
				-webkit-text-fill-color: var(--ion-color-white) !important;
				-webkit-box-shadow: 0 0 0 30px transparent inset !important;
				transition: background-color 5000s ease-in-out 0s;
			}*!
			ion-select {
				width: 100%;
				max-width: 100%;
				padding: 0 0 0 0.5rem;
				font-size: 15px;
				letter-spacing: -0.7px;
				color: var(--ion-color-white);
			}
			&.error {
				input,
				ion-input,
				ion-select,
				input[name="code"],
				ion-input[name="code"],
				input[name="password"],
				ion-input[name="password"] {
					color: var(--ion-color-danger);
				}
				& > .error-label,
				& + .error-label {
					display: block;
				}
				ion-select:after {
					background-image: url(../assets/imgs/arrow-down-small-red.svg);
				}
			}
		}

		&__country {
			width: 100%;
			display: flex;
			align-items: center;

			ion-icon {
				width: 22px; height: 22px;
				min-width: 22px; max-width: 22px;
				margin-right: 9px;
				border-radius: 50%;
			}
		}

		&__phone {
			width: 100%;
			display: flex;
			align-items: center;

			input,
			ion-input {
				&[name="code"] {
					width: auto;
					min-width: 36px; max-width: 36px;
					border-right: 1px solid rgba(255, 255, 255, 0.2);
				}
				&[name="phone"] { --padding-start: 14px; }
			}
		}

		&__separate {
			padding: 8px;
		}

		&__info {
			margin: 0 -16px 25px;
			color: rgba(255, 255, 255, 0.5);
			font-size: 14px;
			line-height: 16px;

			p {
				margin: 10px 0 0;
				font-weight: 600;
				color: var(--ion-color-white);
			}

			@media(min-width: 1280px) {
				margin: 0 -16px 47px;
				p { margin: 12px 0 0; }
			}
		}
	}

	&-buttons {
		margin-top: 24px;
		text-align: center;
		@media(min-width: 1200px) {
			// margin-top: 35px;
		}
		@media(min-width: 1440px) {
			// margin-top: 50px;
		}
		ion-button,
		.bcc-button {
			height: 32px;
			--height: 32px;
			margin: 0;
			font-size: 13px;

			--padding-end: 0;
			--padding-start: 0;

			@media(min-width: 1200px) {
				height: 44px;
				--height: 44px;
				font-size: 15px;
			}

			&[disabled] {
				opacity: 0.3;
			}

			ion-icon {
				left: auto;
				right: 8px;
				margin-right: 0;

				@media(min-width: 1200px) {
				}
			}

			&[size="small"] {
				width: 156px;
				height: 24px;
				--height: 24px;
				font-size: 11px;
				font-weight: 400;

				@media(min-width: 1200px) {
					height: 28px;
					--height: 28px;
					font-size: 12px;
				}
			}

			&[color="white-transparent"] {
				background: rgba(255, 255, 255, 0.15);
				border-radius: 15px;
			}

			&[color="white"] {
				--color: var(--ion-color-white);
				--border-color: var(--ion-color-white);
			}

			&[fill="outline"] {
				--border-width: 2px;
			}

			& + ion-button,
			& + .bcc-button {
				margin-top: 24px;
				&[size="small"] { margin-top: 24px; }

				@media(min-width: 1200px) {
					// margin-top: 41px;
					&[size="small"] { margin-top: 32px; }
				}
				@media(min-width: 1440px) {
					margin-top: 41px;
					&[size="small"] { margin-top: 52px; }
				}
			}
		}
	}
}
input:-moz-placeholder { color: rgba(255, 255, 255, 0.2); }
input::-moz-placeholder { color: rgba(255, 255, 255, 0.2); }
input:-ms-input-placeholder { color: rgba(255, 255, 255, 0.2); }
input::-webkit-input-placeholder { color: rgba(255, 255, 255, 0.2); }
*/
