// ion-popover {
// 	.popover-arrow { display: none; }
// 	.popover-wrapper {
// 		.popover-content {
// 			width: auto;
// 			margin: 0;
// 			border: none;
// 			background-color: transparent;
// 			border-radius: 0;
// 		}
// 	}
// 	&.AccountEdit {}
// }
.cryptoSettingsPopover {
  --min-width: 300px;
  .popover-wrapper {
    .popover-content {
      border-top-color: var(--ion-color-border) !important;
      margin-left: initial !important;
    }
  }
}
