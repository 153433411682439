.checkbox {
  margin: 1.4375rem 0;
  color: var(--ion-color-medium);
  display: flex;
  align-items: center;

  a {
    font-weight: 600;
    text-decoration: underline;
    color: var(--ion-color-medium);

    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
      color: var(--ion-color-medium);
    }
  }

  ion-label {
    font-size: 0.625rem;
    margin: 0;
  }

  // ion-checkbox {
  //   width: 1.5rem;
  //   min-width: 1.5rem;
  //   height: 1.5rem;
  //   margin: 0 0.75rem 0 0;
  //   --size: 0;
  //   --border-width: 0;
  //   --ion-color-success-contrast: var(--ion-color-white);
  //   border: 1px solid var(--ion-color-medium);

  //   border-radius: 0.1875rem;

  //   --ion-color-success-contrast: transparent;

  //   &.checkbox-checked {
  //     background-image: url(../assets/imgs/checkbox-checked.svg);
  //     background-repeat: no-repeat;
  //     background-position: center;
  //     border-color: var(--ion-color-success);

  //     --ion-color-success-contrast: transparent;
  //   }
  // }
}

  ion-checkbox {
        position: relative;
        width: 1.5rem; height: 1.5rem;
        min-width: 1.5rem;
        margin: 0 0.75rem 0 0;
        --size: 0;
        --border-width: 0;
        --background-checked: transparent;
        --ion-color-success-contrast: var(--ion-color-white);
        border: 1px solid transparent;
        border-radius: 0.1875rem;

        &:after {
          content: '';
          position: absolute;
          top: -1px; left: -1px;
          display: block;
          width: 22px; height: 22px;
          border-radius: 0.1875rem;
          border: 1px solid var(--ion-color-medium);
          background-color: var(--ion-color-white);
          pointer-events: none;
        }

        &.checkbox-checked {
          border: 1px solid transparent;
          &:after {
            width: 24px; height: 24px;
            background-image: url(/assets/imgs/checkbox-checked.svg);
            background-repeat: no-repeat;
            background-position: center;
            border: transparent;
          }
        }
      }