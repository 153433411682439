ion-icon {
  width: 1.75rem;
  height: 1.75rem;
  color: transparent;
  /*&[name*="icon-day-limits"],
  &[ng-reflect-name*="icon-day-limits"] {
    background-image: url(../assets/imgs/day-limits.svg);
  }*/
  /*  &[name*="icon-menu-BankTransfer"],
    &[ng-reflect-name*="icon-menu-BankTransfer"] {
      background-image: url(../assets/imgs/menu-BankTransfer.svg);
      .active & { background-image: url(../assets/imgs/menu-BankTransfer-green.svg); }
    }
    &[name*="icon-menu-CardToCardTransfer"],
    &[ng-reflect-name*="icon-menu-CardToCardTransfer"] {
      background-image: url(../assets/imgs/menu-CardToCardTransfer.svg);
      .active & { background-image: url(../assets/imgs/menu-CardToCardTransfer-green.svg); }
    }
    &[name*="icon-menu-CryptoService"],
    &[ng-reflect-name*="icon-menu-CryptoService"] {
      background-image: url(../assets/imgs/menu-CryptoService.svg);
      .active & { background-image: url(../assets/imgs/menu-CryptoService-green.svg); }
    }
    &[name*="icon-menu-CurrencyExchange"],
    &[ng-reflect-name*="icon-menu-CurrencyExchange"] {
      background-image: url(../assets/imgs/menu-CurrencyExchange.svg);
      .active & { background-image: url(../assets/imgs/menu-CurrencyExchange-green.svg); }
    }
    &[name*="icon-menu-Money"],
    &[ng-reflect-name*="icon-menu-Money"] {
      background-image: url(../assets/imgs/menu-Money.svg);
      .active & { background-image: url(../assets/imgs/menu-Money-green.svg); }
    }
    &[name*="icon-menu-SendMoneyRequest"],
    &[ng-reflect-name*="icon-menu-SendMoneyRequest"] {
      background-position: 17% 50%;
      background-image: url(../assets/imgs/menu-SendMoneyRequest.svg);
      .active & { background-image: url(../assets/imgs/menu-SendMoneyRequest-green.svg); }
    }
    &[name*="icon-menu-Settings"],
    &[ng-reflect-name*="icon-menu-Settings"] {
      background-image: url(../assets/imgs/menu-Settings.svg);
      .active & { background-image: url(../assets/imgs/menu-Settings-green.svg); }
    }
    &[name*="icon-menu-Support"],
    &[ng-reflect-name*="icon-menu-Support"] {
      background-image: url(../assets/imgs/menu-Support.svg);
      .active & { background-image: url(../assets/imgs/menu-Support-green.svg); }
    }*/
}
ion-icon[name*=icon-], ion-icon[class*=icon-], ion-icon[ng-reflect-name*=icon-] {
  background-size: auto;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  --background-size: auto;
  --background-color: transparent;
  --background-repeat: no-repeat;
  --background-position: center;
  outline: none;
}
ion-icon[name*=icon-arrow-top-green], ion-icon[ng-reflect-name*=icon-arrow-top-green] {
  background-image: url(../assets/imgs/arrow-top-green.png);
}
ion-icon[name*=icon-topUpBarcode], ion-icon[ng-reflect-name*=icon-topUpBarcode] {
  width: 4rem;
  height: 4rem;
  background-image: url(../assets/imgs/topUpBarcode.svg);
}
ion-icon[name*=icon-topUpPlace], ion-icon[ng-reflect-name*=icon-topUpPlace] {
  width: 4rem;
  height: 4rem;
  background-image: url(../assets/imgs/topUpPlace.svg);
}
ion-icon[name*=icon-cryptoWallet-green], ion-icon[ng-reflect-name*=icon-cryptoWallet-green] {
  background-image: url(../assets/imgs/cryptoWallet-green.svg);
}
ion-icon[name*=icon-support-green], ion-icon[ng-reflect-name*=icon-support-green] {
  background-image: url(../assets/imgs/support-green.svg);
}
ion-icon[name*=icon-settings-green], ion-icon[ng-reflect-name*=icon-settings-green] {
  background-image: url(../assets/imgs/settings-green.svg);
}
ion-icon[name*=icon-cat-bottom], ion-icon[ng-reflect-name*=icon-cat-bottom] {
  background-image: url(../assets/imgs/cat-bottom.png);
}
ion-icon[name*=icon-cat-down], ion-icon[ng-reflect-name*=icon-cat-down] {
  background-image: url(../assets/imgs/cat-down.png);
}
ion-icon[name*=icon-cat-peeking], ion-icon[ng-reflect-name*=icon-cat-peeking] {
  background-image: url(../assets/imgs/cat-peeking.png);
}
ion-icon[name*=cat-info-crypto-receive], ion-icon[ng-reflect-name*=cat-info-crypto-receive] {
  width: 52px;
  height: 66px;
  background-size: 100% auto;
  background-image: url(../assets/imgs/cat-info-crypto-receive@2x.png);
}
ion-icon[name*=cat-info-crypto-confirmation], ion-icon[ng-reflect-name*=cat-info-crypto-confirmation] {
  width: 79px;
  height: 25px;
  background-size: 100% auto;
  background-image: url(../assets/imgs/cat-info-crypto-confirmation@2x.png);
}
ion-icon[name*=icon-close], ion-icon[ng-reflect-name*=icon-close] {
  background-image: url(../assets/imgs/close.svg);
}
ion-icon[name*=icon-close-small-red], ion-icon[ng-reflect-name*=icon-close-small-red] {
  background-image: url(../assets/imgs/close-small-red.svg);
}
ion-icon[name*=icon-arrow-slider], ion-icon[ng-reflect-name*=icon-arrow-slider] {
  background-image: url(../assets/imgs/arrow-slider.svg);
}
ion-icon[name*=icon-eur], ion-icon[ng-reflect-name*=icon-eur] {
  background-image: url(../assets/imgs/eur.svg);
}
ion-icon[name*=icon-eur-white], ion-icon[ng-reflect-name*=icon-eur-white] {
  background-image: url(../assets/imgs/eur-white.svg);
}
ion-icon[name*=icon-eur-png], ion-icon[ng-reflect-name*=icon-eur-png] {
  background-image: url(../assets/imgs/icon-eur-white.png);
}
ion-icon[name*=icon-Free-ATM-Withdrawal], ion-icon[ng-reflect-name*=icon-Free-ATM-Withdrawal] {
  background-image: url(../assets/imgs/Free-ATM-Withdrawal.svg);
}
ion-icon[name*=icon-month-limits], ion-icon[ng-reflect-name*=icon-month-limits] {
  background-image: url(../assets/imgs/month-limits.svg);
}
ion-icon[name*=icon-disable-limits-temporarily], ion-icon[ng-reflect-name*=icon-disable-limits-temporarily] {
  background-image: url(../assets/imgs/disable-limits-temporarily.svg);
}
ion-icon[name*=icon-print], ion-icon[ng-reflect-name*=icon-print] {
  background-image: url(../assets/imgs/print.svg);
}
ion-icon[name*=icon-print-active], ion-icon[ng-reflect-name*=icon-print-active] {
  background-image: url(../assets/imgs/print-active.svg);
}
ion-icon[name*=icon-history-refund], ion-icon[ng-reflect-name*=icon-history-refund] {
  background-image: url(../assets/imgs/history-refund.svg);
}
ion-icon[name*=icon-history-gas], ion-icon[ng-reflect-name*=icon-history-gas] {
  background-image: url(../assets/imgs/history-gas.svg);
}
ion-icon[name*=icon-history-exchange], ion-icon[ng-reflect-name*=icon-history-exchange] {
  background-image: url(../assets/imgs/history-exchange.svg);
}
ion-icon[name*=icon-identity-status], ion-icon[ng-reflect-name*=icon-identity-status] {
  background-image: url(../assets/imgs/identity-status.svg);
}
ion-icon[name*=icon-change-application-pin], ion-icon[ng-reflect-name*=icon-change-application-pin] {
  background-image: url(../assets/imgs/change-application-pin.svg);
}
ion-icon[name*=icon-protection], ion-icon[ng-reflect-name*=icon-protection] {
  background-image: url(../assets/imgs/protection.svg);
}
ion-icon[name*=icon-legal-notices], ion-icon[ng-reflect-name*=icon-legal-notices] {
  background-image: url(../assets/imgs/legal-notices.svg);
}
ion-icon[name*=icon-third-part], ion-icon[ng-reflect-name*=icon-third-part] {
  background-image: url(../assets/imgs/third-part.svg);
}
ion-icon[name*=icon-blocked], ion-icon[ng-reflect-name*=icon-blocked] {
  background-image: url(../assets/imgs/blocked.svg);
}
ion-icon[name*=icon-enable], ion-icon[ng-reflect-name*=icon-enable] {
  background-image: url(../assets/imgs/enable.svg);
}
ion-icon[name*=icon-read], ion-icon[ng-reflect-name*=icon-read] {
  background-image: url(../assets/imgs/icon-read.svg);
}
ion-icon[name*=icon-heading-off], ion-icon[ng-reflect-name*=icon-heading-off] {
  background-image: url(../assets/imgs/icon-heading-off.svg);
}
ion-icon[name*=icon-sent], ion-icon[ng-reflect-name*=icon-sent] {
  background-image: url(../assets/imgs/icon-sent.svg);
}
ion-icon[name*=icon-anonymously], ion-icon[ng-reflect-name*=icon-anonymously] {
  background-image: url(../assets/imgs/support-anonymously-bank-avatar.svg);
}
ion-icon[name*=icon-file-in-chat], ion-icon[ng-reflect-name*=icon-file-in-chat] {
  background-image: url(../assets/imgs/file-in-chat.svg);
}
ion-icon[name*=icon-file-in-chat-white], ion-icon[ng-reflect-name*=icon-file-in-chat-white] {
  background-image: url(../assets/imgs/file-in-chat-white.svg);
}
ion-icon[name*=icon-crypto-wallet], ion-icon[ng-reflect-name*=icon-crypto-wallet] {
  background-image: url(../assets/imgs/crypto-wallet.svg);
}
ion-icon[name*=icon-crypto-wallet-large], ion-icon[ng-reflect-name*=icon-crypto-wallet-large] {
  width: 82px;
  height: 82px;
  background-image: url(../assets/imgs/crypto-wallet-large.svg);
}
ion-icon[name*=icon-crypto-dash], ion-icon[ng-reflect-name*=icon-crypto-dash] {
  background-image: url(../assets/imgs/crypto-dash.svg);
}
ion-icon[name*=icon-crypto-etherum], ion-icon[ng-reflect-name*=icon-crypto-etherum] {
  background-image: url(../assets/imgs/crypto-etherum.svg);
}
ion-icon[name*=icon-crypto-monero], ion-icon[ng-reflect-name*=icon-crypto-monero] {
  background-image: url(../assets/imgs/crypto-monero.svg);
}
ion-icon[name*=icon-crypto-litcoin], ion-icon[ng-reflect-name*=icon-crypto-litcoin] {
  background-image: url(../assets/imgs/crypto-litcoin.svg);
}
ion-icon[name*=icon-send], ion-icon[ng-reflect-name*=icon-send] {
  background-image: url(../assets/imgs/icon-send.svg);
}
ion-icon[name*=icon-deposit], ion-icon[ng-reflect-name*=icon-deposit] {
  background-image: url(../assets/imgs/icon-deposit.svg);
}
ion-icon[name*=icon-receive], ion-icon[ng-reflect-name*=icon-receive] {
  background-image: url(../assets/imgs/icon-receive.svg);
}
ion-icon[name*=icon-receive-green], ion-icon[ng-reflect-name*=icon-receive-green] {
  background-image: url(../assets/imgs/icon-receive-green.svg);
}
ion-icon[name*=icon-repo], ion-icon[ng-reflect-name*=icon-repo] {
  background-image: url(../assets/imgs/icon-repo.svg);
}
ion-icon[name*=icon-exclamation-mark], ion-icon[ng-reflect-name*=icon-exclamation-mark] {
  background-image: url(../assets/imgs/exclamation-mark.svg);
}
ion-icon[name*=icon-map], ion-icon[ng-reflect-name*=icon-map] {
  background-image: url(../assets/imgs/icon-map.svg);
}
ion-icon[name*=icon-receipt-green], ion-icon[ng-reflect-name*=icon-receipt-green] {
  background-image: url(../assets/imgs/receipt-green.svg);
}
ion-icon[name*=icon-full], ion-icon[ng-reflect-name*=icon-full] {
  background-image: url(../assets/imgs/icon-full.svg);
}
ion-icon[name*=icon-country--germany], ion-icon[ng-reflect-name*=icon-country--germany] {
  background-image: url(../assets/imgs/icon-country--germany.png);
}
ion-icon[name*=icon-plus-green], ion-icon[ng-reflect-name*=icon-plus-green] {
  background-image: url(../assets/imgs/icon-plus-green.svg);
}
ion-icon[name*=icon-inactive], ion-icon[ng-reflect-name*=icon-inactive] {
  background-image: url(../assets/imgs/icon-inactive.svg);
}
ion-icon[name*=icon-settings-off-on], ion-icon[ng-reflect-name*=icon-settings-off-on] {
  background-image: url(../assets/imgs/icon-settings-off-on.svg);
}
ion-icon[name*=icon-settings-qr], ion-icon[ng-reflect-name*=icon-settings-qr] {
  background-image: url(../assets/imgs/icon-settings-qr.svg);
}
ion-icon[name*=icon-settings-qr-green], ion-icon[ng-reflect-name*=icon-settings-qr-green] {
  background-image: url(../assets/imgs/icon-settings-qr-green.svg);
}
ion-icon[name*=icon-settings-notifications], ion-icon[ng-reflect-name*=icon-settings-notifications] {
  background-image: url(../assets/imgs/icon-settings-notifications.svg);
}
ion-icon[name*=icon-with-draw], ion-icon[ng-reflect-name*=icon-with-draw] {
  background-image: url(../assets/imgs/with-draw.svg);
}
ion-icon[name*=icon-pen], ion-icon[ng-reflect-name*=icon-pen] {
  background-image: url(../assets/imgs/icon-pen.svg);
}
ion-icon[name*=message-error], ion-icon[ng-reflect-name*=message-error] {
  background-image: url(../assets/imgs/error.svg);
}
ion-icon[name*=icon-gbp], ion-icon[ng-reflect-name*=icon-gbp] {
  background-image: url(../assets/imgs/gbp.svg);
}
ion-icon[name*=icon-rub], ion-icon[ng-reflect-name*=icon-rub] {
  background-image: url(../assets/imgs/rub.svg);
}
ion-icon[name*=icon-usd], ion-icon[ng-reflect-name*=icon-usd] {
  background-image: url(../assets/imgs/usd.svg);
}
ion-icon[name*=link], ion-icon[ng-reflect-name*=link] {
  background-image: url(../assets/custom-icons/link.svg);
}

.main-app {
  display: flex;
  justify-content: flex-start;
  overflow: auto;
}
.main-app > ion-split-pane {
  position: static;
  width: 100%;
  min-width: 1200px;
  height: 100%;
  max-height: 100%;
}
@media (min-width: 1024px) {
  .main-app {
    align-items: center;
  }
}
@media (max-width: 961px) {
  .main-app > ion-split-pane {
    min-width: initial;
    max-width: initial;
  }
}
@media (min-width: 1920px) {
  .main-app > ion-split-pane {
    width: 1680px;
  }
}

ion-list,
.list-md,
.list-ios {
  padding: 0;
  background: transparent;
}

.header,
ion-header {
  text-align: center;
  border-bottom: 1px solid var(--ion-color-border);
}
.header:after,
ion-header:after {
  display: none;
}
.header ion-toolbar,
ion-header ion-toolbar {
  --background: var(--ion-color-white);
}
.header ion-title,
ion-header ion-title {
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: -0.4px;
  color: var(--ion-color-black);
}

ion-toolbar {
  --padding-top: 0;
  --padding-bottom: 0;
  --padding-end: 0;
  --padding-start: 0;
  --min-height: 3.4375rem;
}

ion-router-outlet[name=rightBox] ion-toolbar {
  --min-height: 3.4375rem;
}

.page {
  height: auto;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.page-list {
  margin: 0 0 2rem;
  background-color: transparent;
  border-radius: 0.3125rem;
  overflow: hidden;
}
.page-list ion-item,
.page-list .item {
  --min-height: 37.5px;
  --border-width: 0.5px;
  --inner-padding-end: 16px;
  margin: 0 0 -1px;
  font-weight: 400;
  font-size: 0.8125rem;
  background-color: var(--ion-color-white);
}
.page-list ion-item .item-has-focus,
.page-list .item .item-has-focus {
  outline: none;
}
.page-list ion-item:last-child,
.page-list .item:last-child {
  margin: 0;
  --border-radius: 0 0 0.3125rem 0.3125rem;
}
.page-list ion-item:last-child:first-child,
.page-list .item:last-child:first-child {
  --border-radius: 0.3125rem;
}
.page-list ion-item:first-child,
.page-list .item:first-child {
  --border-radius: 0.3125rem 0.3125rem 0 0;
}
.page-list ion-item:first-child:last-child,
.page-list .item:first-child:last-child {
  --border-radius: 0.3125rem;
}
.page-list ion-item.item-comment ion-label,
.page-list .item.item-comment ion-label {
  margin-bottom: 15px;
}
.page-list ion-item ion-label,
.page-list .item ion-label {
  max-width: 50%;
  margin-top: 0;
  margin-bottom: 0;
  color: var(--ion-color-black);
}
.page-list ion-item.item-select ion-label,
.page-list .item.item-select ion-label {
  max-width: none;
  flex: 1;
}
.page-list ion-item textarea,
.page-list ion-item ion-input,
.page-list ion-item ion-select,
.page-list .item textarea,
.page-list .item ion-input,
.page-list .item ion-select {
  width: 100%;
  min-width: 100%;
  flex: auto;
  text-align: right;
  white-space: normal;
  padding-left: 0;
  padding-top: 0;
  padding-bottom: 0;
}
.page-list ion-item textarea,
.page-list .item textarea {
  min-height: 15px;
  height: 30px;
  line-height: 15px;
  border: none;
  margin: 13px 0;
  resize: none;
}
.page-list ion-item textarea:hover, .page-list ion-item textarea:focus, .page-list ion-item textarea:active,
.page-list .item textarea:hover,
.page-list .item textarea:focus,
.page-list .item textarea:active {
  outline: none;
}
.page-list ion-item textarea::-moz-placeholder,
.page-list .item textarea::-moz-placeholder {
  line-height: 15px;
  color: rgba(0, 0, 0, 0.33);
}
.page-list ion-item textarea::-webkit-input-placeholder,
.page-list .item textarea::-webkit-input-placeholder {
  line-height: 15px;
  color: rgba(0, 0, 0, 0.33);
}
.page-list ion-item textarea:-ms-input-placeholder,
.page-list .item textarea:-ms-input-placeholder {
  line-height: 15px;
  color: rgba(0, 0, 0, 0.33);
}
.page-list ion-item ion-select,
.page-list .item ion-select {
  position: static;
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: -8px;
  line-height: 1.1875rem;
  --min-width: 100%;
}
.page-list ion-item ion-select:after,
.page-list .item ion-select:after {
  width: 14px;
  top: 1px;
}
.page-list ion-item ion-select[interface=popover],
.page-list .item ion-select[interface=popover] {
  min-width: 199px;
  max-width: 199px;
}
.page-list ion-item ion-toggle,
.page-list .item ion-toggle {
  margin-right: 10px;
}
.page-list ion-item.error,
.page-list .item.error {
  position: relative;
  overflow: visible;
  --border-color: var(--ion-color-danger);
  --border-width: 0.5px;
}
.page-list ion-item.error + .item:not(.error),
.page-list .item.error + .item:not(.error) {
  --border-width: 0 0.5px;
}
.page-list ion-item.error + .item.error, .page-list ion-item.error + .item:last-child,
.page-list .item.error + .item.error,
.page-list .item.error + .item:last-child {
  --border-width: 0 0.5px 0.5px;
}
.page-list ion-item.error ion-input,
.page-list ion-item.error ion-select,
.page-list .item.error ion-input,
.page-list .item.error ion-select {
  color: var(--ion-color-danger);
  --placeholder-color: var(--ion-color-danger);
}
.page-list ion-item.item-disabled,
.page-list .item.item-disabled {
  opacity: 0.5;
}
@media (min-width: 1280px) {
  .page-list ion-item,
  .page-list .item {
    --min-height: 47.5px;
  }
  .page-list ion-item ion-select,
  .page-list .item ion-select {
    margin-right: -8px;
  }
  .page-list ion-item ion-select[interface=popover],
  .page-list .item ion-select[interface=popover] {
    min-width: 222px;
    max-width: 222px;
  }
}
@media (min-width: 1440px) {
  .page-list ion-item,
  .page-list .item {
    --min-height: 47.1px;
    --inner-padding-end: 16px;
  }
}
.page-list__value {
  margin-top: 9px;
  margin-bottom: 9px;
  color: var(--ion-color-dark);
  text-align: right;
  letter-spacing: -0.1px;
}
@media (min-width: 1280px) {
  .page-list__value {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }
}
.page-field {
  --min-height: auto;
  --inner-padding-end: 16px;
  margin-bottom: 6px;
  letter-spacing: 0.1px;
}
.page-total {
  margin-bottom: 2px;
  font-size: 11px;
  letter-spacing: 0.1px;
  opacity: 0.33;
}
.page-total small {
  font-size: 9px;
}
.page-total__value {
  letter-spacing: 0.1px;
}
@media (min-width: 1280px) {
  .page-total {
    margin-bottom: 9px;
  }
}
.page-balance {
  font-size: 13px;
  letter-spacing: -0.1px;
  color: var(--ion-color-black);
}
.page-balance small {
  font-size: 11px;
}
.page-balance__value {
  color: var(--ion-color-success);
  letter-spacing: 0.2px;
}
.page-group {
  --min-height: 52px;
  --padding-start: 14px;
  margin-top: 7px;
  font-weight: 600;
  font-size: 0.8125rem;
  border-radius: 0.3125rem;
  border: 2px solid var(--ion-color-success);
  --show-full-highlight: 0;
  --background-hover: transparent;
}
.page-group small {
  font-size: 0.6875rem;
}
.page-group__value {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.page-group__value ion-input {
  position: relative;
  z-index: 2;
  text-align: right;
  --padding-end: 0;
}
.page-group__value span,
.page-group__value ion-select {
  z-index: 0;
  --padding-top: 0;
  --padding-start: 0;
  --padding-bottom: 0;
  --padding-end: 8px;
  line-height: 19px;
  font-size: 0.6875rem;
  margin-left: 3px;
  margin-top: 1px;
}
.page-group__value span:after,
.page-group__value ion-select:after {
  width: 20px;
}
.page-group.error .page-group__value {
  color: var(--ion-color-danger);
}
@media (min-width: 1280px) {
  .page-group {
    --min-height: 52px;
  }
  .page-group__value ion-select {
    --padding-end: 8px;
  }
  .page-group__value ion-select:after {
    width: 20px;
  }
}
.page-next {
  margin-top: 30px;
  text-align: center;
}
.page-next .button {
  width: 175px;
  height: 32px;
  margin-right: 15px;
  display: inline-block;
}
.page-next .button ion-icon {
  left: -30px;
}
@media (min-width: 1280px) {
  .page-next {
    margin-top: 30px;
  }
  .page-next .button {
    width: 212px;
    height: 40px;
    margin-right: 15px;
  }
}

.customScrollbar {
  height: 100%;
  overflow-y: auto;
}

::-webkit-scrollbar-track,
.customScrollbar::-webkit-scrollbar-track {
  border-radius: 3px;
  background-color: transparent;
}

::-webkit-scrollbar,
.customScrollbar::-webkit-scrollbar {
  width: 4px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb,
.customScrollbar::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: #dddddd;
}

app-support-chat .dark .Chat__bottom {
  border-radius: 15px 15px 0 0;
}

.top-up {
  height: calc(100% - 60px);
  padding: 32px 24px 0;
  background-color: #FFFFFF;
}
.top-up__warning-banner {
  padding: 14px 16px;
  gap: 16px;
  border-radius: 4px;
  background-color: #FAEBEB;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  color: #F20D0D;
}
.top-up__main, .top-up-list, .top-up__footer, .top-up__footer-button, .top-up__warning-banner, .top-up__footer-copy-message {
  display: flex;
}
.top-up__main, .top-up-list {
  flex-direction: column;
}
.top-up__main {
  gap: 24px;
}
.top-up-list__item {
  padding: 12px 0;
  display: flex;
  justify-content: space-between;
}
.top-up-list__item:not(:last-of-type) {
  border-bottom: 1px solid var(--ion-color-border);
}
.top-up-list__label, .top-up-list__value {
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.23px;
}
.top-up-list__label {
  color: #8F96A3;
}
.top-up-list__value {
  color: #000;
}
.top-up__footer {
  height: 60px;
  position: relative;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: #FFFFFF;
}
.top-up__footer-button {
  width: 100%;
  padding: 15px 0;
  align-items: center;
  justify-content: center;
  gap: 4px;
  background: none;
  border-top: 1px solid var(--ion-color-border);
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  color: #1B1B1B;
}
.top-up__footer-button_copy {
  color: #77A45C;
}
.top-up__footer-button_copy ion-icon {
  color: #77A45C;
}
.top-up__footer-button_back ion-icon {
  color: #1B1B1B;
}
.top-up__footer-copy-message {
  position: absolute;
  bottom: 70px;
  justify-content: center;
  align-items: center;
  color: #8F96A3;
}

small {
  font-size: 90%;
}

.History-filter__value small {
  font-size: 11px;
}

a {
  transition: all 0.25s;
  text-decoration: underline;
  color: var(--ion-color-success);
}
a:hover, a:focus, a:active {
  text-decoration: none;
  color: var(--ion-color-success);
}

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.linkified {
  color: var(--ion-color-success-contrast);
}
.linkified:hover, .linkified:focus, .linkified:active {
  text-decoration: underline;
  color: var(--ion-color-success-contrast);
}

.modal-wrapper,
.modal-wrapper.sc-ion-modal-md {
  width: 34.3125rem;
  max-height: 96%;
  border-radius: 15px;
  box-shadow: none;
}

@media (max-width: 961px) {
  ion-modal .modal-wrapper {
    border-radius: initial !important;
    max-height: initial !important;
  }
}
ion-modal.FileFormatWarning {
  --width: 650px;
  --height: 250px;
}
ion-modal.FileFormatWarning .CardAlert__text {
  text-align: center;
}
ion-modal.CameraNotFound {
  --width: 650px;
  --height: 350px;
}
ion-modal.autoHeight {
  --width: 650px;
  --min-height: 300px;
  --height: auto;
}
ion-modal.PaymentReceipt {
  --width: 650px;
  --height: 500px;
}
@media (min-width: 1280px) {
  ion-modal.PaymentReceipt {
    --width: 650px;
    --height: 500px;
  }
}
ion-modal.ComingSoon {
  --width: 520px;
  --height: 153px;
}
@media (min-width: 1280px) {
  ion-modal.ComingSoon {
    --width: 520px;
    --height: 153px;
  }
}
ion-modal.PrintStatementPreview {
  --width: 520px;
  --height: 620px;
}
@media (min-width: 1280px) {
  ion-modal.PrintStatementPreview {
    --width: 520px;
    --height: 620px;
  }
}
ion-modal.TopUp {
  --width: 473px;
  --height: 473px;
}
@media (min-width: 1280px) {
  ion-modal.TopUp {
    --width: 650px;
    --height: 600px;
  }
}
ion-modal.TopUpErrModal {
  --width: 473px;
  --height: 473px;
  --border-radius: 16px;
}
@media (min-width: 1280px) {
  ion-modal.TopUpErrModal {
    --width: 650px;
    --height: 600px;
  }
}
ion-modal.TopUpInactive {
  --width: 520px;
  --height: 295px;
}
@media (min-width: 1280px) {
  ion-modal.TopUpInactive {
    --width: 520px;
    --height: 295px;
  }
}
ion-modal.BinanceRemove {
  --width: 410px;
  --height: auto;
}
@media (min-width: 1280px) {
  ion-modal.BinanceRemove {
    --width: 650px;
    --height: auto;
  }
}
ion-modal.TopUpSuccess {
  --width: 473px;
  --height: 473px;
}
@media (min-width: 1280px) {
  ion-modal.TopUpSuccess {
    --width: 514px;
    --height: 599px;
  }
}
ion-modal.TopUpFail {
  --width: 650px;
  --height: 318px;
}
@media (min-width: 1280px) {
  ion-modal.TopUpFail {
    --width: 650px;
    --height: 318px;
  }
}
ion-modal.SelectCountry {
  --width: 100%;
  --height: 100vh;
}
@media (min-width: 576px) {
  ion-modal.SelectCountry {
    --width: 520px;
    --height: 546px;
    --border-radius: 16px;
  }
}
ion-modal.SelectCountry ion-backdrop {
  opacity: 1 !important;
}
ion-modal.CardBlock {
  --width: 650px;
  --height: 328px;
}
@media (min-width: 1280px) {
  ion-modal.CardBlock {
    --width: 650px;
    --height: 328px;
  }
}
ion-modal.CardUnblock {
  --width: 650px;
  --height: 318px;
}
@media (min-width: 1280px) {
  ion-modal.CardUnblock {
    --width: 650px;
    --height: 318px;
  }
}
ion-modal.CardDetail {
  --width: 286px;
  --height: 281px;
}
@media (min-width: 1280px) {
  ion-modal.CardDetail {
    --width: 650px;
    --height: 318px;
  }
}
ion-modal.CardData {
  --width: 374px;
  --height: 303px;
}
@media (min-width: 1280px) {
  ion-modal.CardData {
    --width: 23rem;
    --height: 23.375rem;
  }
}
ion-modal.CardLimitDisable {
  --width: 650px;
  --height: 318px;
}
@media (min-width: 1280px) {
  ion-modal.CardLimitDisable {
    --width: 650px;
    --height: 318px;
  }
}
ion-modal.CardDayLimits, ion-modal.CardMonthLimits {
  --width: 480px;
  --height: 218px;
}
@media (min-width: 1280px) {
  ion-modal.CardDayLimits, ion-modal.CardMonthLimits {
    --width: 32.5rem;
    --height: 260px;
  }
}
ion-modal.CardLimitsConfirmation {
  --width: 480px;
  --height: 271px;
}
@media (min-width: 1280px) {
  ion-modal.CardLimitsConfirmation {
    --width: 32.5rem;
    --height: 20.8125rem;
  }
}
ion-modal.InterestRateConfirm {
  --height: 18.5rem;
  --overflow: visible;
}
ion-modal.InterestRateConfirm .ion-page {
  overflow: visible;
}
ion-modal.TransferConfirmationModal {
  --width: 480px;
  --height: 271px;
}
@media (min-width: 1280px) {
  ion-modal.TransferConfirmationModal {
    --width: 32.5rem;
    --height: 20.8125rem;
  }
}
ion-modal.TransferConfirmationModal.TransferToContact {
  --height: 287px;
}
ion-modal.GekkoinAttachWallet {
  --width: 550px;
  --height: 500px;
}
@media (min-width: 1280px) {
  ion-modal.GekkoinAttachWallet {
    --width: 32.5rem;
    --height: 37.8125rem;
  }
}
ion-modal.TemplateEnterNameModal {
  --width: 480px;
  --height: 271px;
}
@media (min-width: 1280px) {
  ion-modal.TemplateEnterNameModal {
    --width: 650px;
    --height: 280px;
  }
}
ion-modal.SendMoneyRequestReceive {
  --width: 480px;
  --height: 248px;
}
@media (min-width: 1280px) {
  ion-modal.SendMoneyRequestReceive {
    --width: 520px;
    --height: 316px;
  }
}
ion-modal.SendMoneyRequestPayment {
  --width: 480px;
  --height: 474px;
}
@media (min-width: 1280px) {
  ion-modal.SendMoneyRequestPayment {
    --width: 32.125rem;
    --height: 39.8125rem;
  }
}
ion-modal.SendMoneyRequestConfirmation {
  --width: 480px;
  --height: 474px;
}
@media (min-width: 1280px) {
  ion-modal.SendMoneyRequestConfirmation {
    --width: 514px;
    --height: 562px;
  }
}
ion-modal.SendMoneyRequestBanner {
  --width: 520px;
  --height: 358px;
}
@media (min-width: 1280px) {
  ion-modal.SendMoneyRequestBanner {
    --width: 650px;
    --height: 372px;
  }
}
ion-modal.PersonalInfoEdit {
  --width: 700px;
  --height: 432px;
}
@media (min-width: 1280px) {
  ion-modal.PersonalInfoEdit {
    --width: 33.5rem;
    --height: 29.5rem;
  }
}
ion-modal.SettingsIdentityStatus {
  --width: 500px;
  --height: 300px;
}
@media (min-width: 1280px) {
  ion-modal.SettingsIdentityStatus {
    --width: 560px;
    --height: 360px;
  }
}
ion-modal.SettingsChangePassword {
  --width: 520px;
  --height: 438px;
}
@media (min-width: 1280px) {
  ion-modal.SettingsChangePassword {
    --width: 520px;
    --height: 438px;
  }
}
ion-modal.SettingsLogOut {
  --width: 480px;
  --height: 255px;
}
@media (min-width: 1280px) {
  ion-modal.SettingsLogOut {
    --width: 32.5rem;
    --height: 18.8125rem;
  }
}
ion-modal.SettingsMyTariff {
  --width: 700px;
  --height: 472px;
}
@media (min-width: 1280px) {
  ion-modal.SettingsMyTariff {
    --width: 999px;
    --height: 603px;
  }
}
ion-modal.ProtectionPolicy, ion-modal.TermsAndConditions, ion-modal.ThirdPartySoftwareLibraries {
  --width: 700px;
  --height: 472px;
}
@media (min-width: 1280px) {
  ion-modal.ProtectionPolicy, ion-modal.TermsAndConditions, ion-modal.ThirdPartySoftwareLibraries {
    --width: 999px;
    --height: 610px;
  }
}
ion-modal.InterestRateInsFunds {
  --height: 14.3125rem;
}
ion-modal.InterestRateStopUsing {
  --height: 13.875rem;
}
ion-modal.RegistrationTerms {
  --width: 700px;
  --height: 473px;
}
@media (min-width: 1280px) {
  ion-modal.RegistrationTerms {
    --width: 999px;
    --height: 527px;
  }
}
ion-modal.OnOffCryptoCurrency {
  --width: 479px;
  --height: 403px;
}
@media (min-width: 1280px) {
  ion-modal.OnOffCryptoCurrency {
    --width: 514px;
    --height: 459px;
  }
}
ion-modal.EditAddress {
  --width: 479px;
  --height: 473px;
}
@media (min-width: 1280px) {
  ion-modal.EditAddress {
    --width: 524px;
    --height: 637px;
  }
}
ion-modal.StopCashback {
  --width: 650px;
  --height: 234px;
}
@media (min-width: 1280px) {
  ion-modal.StopCashback {
    --width: 650px;
    --height: 234px;
  }
}
ion-modal.InsufficientFunds {
  --width: 650px;
  --height: 200px;
}
@media (min-width: 1280px) {
  ion-modal.InsufficientFunds {
    --width: 650px;
    --height: 200px;
  }
}
ion-modal.ConfirmCashback {
  --width: 650px;
  --height: 353px;
}
@media (min-width: 1280px) {
  ion-modal.ConfirmCashback {
    --width: 650px;
    --height: 353px;
  }
}
ion-modal.SuccessCashback {
  --width: 650px;
  --height: 500px;
}
@media (min-width: 1280px) {
  ion-modal.SuccessCashback {
    --width: 650px;
    --height: 500px;
  }
}
ion-modal.ModalConfirmation {
  --background-color: transparent;
}
ion-modal.ModalConfirmation .ion-page {
  --padding-bottom: 53px;
  --background-color: transparent;
}
ion-modal.ModalConfirmation .ModalConfirmation__window {
  --border-radius: 1rem;
  --background-color: var(--ion-color-white);
}
ion-modal.ModalConfirmation .ModalConfirmation__resend {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
}
ion-modal.ModalConfirmation .ModalConfirmation__resend ion-button {
  display: inline-block;
  width: 165px;
  height: 30px;
  --height: 30px;
  line-height: 1.75rem;
  font-size: 0.8125rem;
  font-weight: 400;
  color: var(--ion-color-white);
  --border-color: var(--ion-color-white);
}
@media (min-width: 1280px) {
  ion-modal.ModalConfirmation .ModalConfirmation__resend ion-button {
    width: 202px;
  }
}
@media (min-width: 1280px) {
  ion-modal.ModalConfirmation .ion-page {
    padding-bottom: 73px;
  }
}
ion-modal .Modal,
ion-modal .ion-page {
  --width: 100%;
  --height: 100%;
  --border-radius: 1rem;
  --background-color: var(--ion-color-white);
}
ion-modal.ModalFullScreen {
  --width: 100%;
  --height: 100%;
  --max-height: 100%;
  --border-radius: 0;
}
ion-modal.ModalFullScreen ion-backdrop {
  opacity: 0;
}
ion-modal.ModalFullScreen .ion-page {
  --border-radius: 0;
}

.ConfirmDialog {
  --height: 65% ;
}

@media (min-width: 600px) {
  .ConfirmDialog {
    --height: 250px ;
  }
}
.CardAlert {
  padding: 24px 55px;
  font-size: 0.8125rem;
  font-weight: 400;
  letter-spacing: -0.1px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 961px) {
  .CardAlert {
    padding: 24px;
  }
}
.CardAlert p {
  margin: 0;
}
.CardAlert__icon ion-icon {
  width: 100px;
  height: 100px;
  border: 1px solid var(--ion-color-border);
  background-size: 56px auto;
  border-radius: 50%;
  vertical-align: middle;
}
.CardDetail .CardAlert__icon ion-icon {
  background-size: 32px auto;
}
.CardAlert__icon + .CardAlert__title {
  margin-top: 24px;
}
.CardAlert__title {
  font-weight: 600;
  font-size: 16px;
}
.CardAlert__title + .CardAlert__icon {
  margin-top: 24px;
}
.CardAlert__card {
  margin: 24px auto 0;
}
.CardAlert__card-number {
  margin-bottom: 8px;
}
.CardAlert .card {
  width: 16.5rem;
  height: 8.125rem;
  padding: 19.5px 1.0625rem 10px;
  border-radius: 15px;
  border: solid 1px #dee4e8;
}
.CardAlert .card-value {
  font-family: "OCRAEXT";
  font-size: 19px;
  font-weight: 600;
  display: block;
  text-wrap: nowrap;
}
.CardAlert .card ion-label {
  margin: 0 0 2px;
  font-family: "SFProText";
  font-weight: 600;
  font-size: 0.5rem;
  color: var(--ion-color-dark);
  text-transform: uppercase;
}
.CardAlert .card ion-grid ion-col:first-child {
  padding-right: 12px;
}
.CardAlert .card ion-grid ion-col:last-child {
  padding-left: 20px;
}
.CardAlert__text {
  margin-top: 24px;
}
.CardAlert__buttons {
  padding-top: 24px;
}
.CardAlert__buttons ion-button {
  width: 174px;
  height: 32px;
  --height: 32px;
  display: inline-block;
}
.CardAlert__buttons ion-button + ion-button {
  margin-left: 26px;
}
.CardAlert__field {
  margin-top: 24px;
  text-align: center;
}
.CardAlert__field input,
.CardAlert__field ion-input {
  width: 570px;
  max-width: 100%;
  height: 38px;
  display: inline-block;
  font-size: 1.0625rem;
  line-height: 38px;
  border: none;
  outline: none;
  border-radius: 0.3125rem;
  background-color: var(--ion-color-light);
  --placeholder-color: var(--ion-color-gray);
}
.CardBlock .CardAlert, .CardUnblock .CardAlert {
  padding-top: 24px;
}
.CardBlock .CardAlert .CardAlert__text, .CardUnblock .CardAlert .CardAlert__text {
  width: 17rem;
  margin: 16px auto 0;
  padding-left: 6px;
}
.CardUnblock .CardAlert {
  padding: 24px 50px;
}
.CardUnblock .CardAlert .CardAlert__icon + .CardAlert__title {
  margin-top: 25px;
}
.CardUnblock .CardAlert .CardAlert__buttons {
  padding-top: 23px;
  padding-left: 7px;
}
.CardDetail .CardAlert {
  padding: 24px 55px;
}
.CardDetail .CardAlert .CardAlert__text {
  margin-top: 24px;
}
.CardDetail .CardAlert .CardAlert__buttons {
  padding-top: 24px;
}
.CardDayLimits .CardAlert__field ion-input, .CardMonthLimits .CardAlert__field ion-input {
  --padding-end: 32px;
}
.CardDayLimits .CardAlert, .CardMonthLimits .CardAlert, .CardLimitsConfirmation .CardAlert {
  padding-top: 28px;
}
.CardDayLimits .CardAlert .CardAlert__title, .CardMonthLimits .CardAlert .CardAlert__title, .CardLimitsConfirmation .CardAlert .CardAlert__title {
  padding-right: 4px;
}
.CardDayLimits .CardAlert .CardAlert__text, .CardMonthLimits .CardAlert .CardAlert__text, .CardLimitsConfirmation .CardAlert .CardAlert__text {
  margin-top: 16px;
  padding-right: 4px;
}
.CardDayLimits .CardAlert .CardAlert__buttons, .CardMonthLimits .CardAlert .CardAlert__buttons, .CardLimitsConfirmation .CardAlert .CardAlert__buttons {
  padding-top: 24px;
}
.CardLimitDisable .CardAlert {
  padding: 25px 50px 23px;
}
.CardLimitDisable .CardAlert .CardAlert__title {
  padding-right: 4px;
}
.CardLimitDisable .CardAlert .CardAlert__text {
  padding-left: 4px;
}
.CardLimitDisable .CardAlert .CardAlert__buttons {
  padding-left: 4px;
}
@media (min-width: 1280px) {
  .CardAlert {
    padding: 2.125rem 40px 2rem;
  }
  .CardAlert__title {
    padding-right: 5px;
  }
  .CardAlert__title + .CardAlert__icon {
    margin-top: 25px;
  }
  .CardAlert__icon + .CardAlert__title {
    margin-top: 24px;
  }
  .CardAlert__card {
    margin: 2rem auto 0;
  }
  .CardAlert__card-number {
    margin-bottom: 7px;
  }
  .CardAlert .card {
    padding: 1.9375rem 1.0625rem 1.875rem;
  }
  .CardAlert .card-value {
    font-size: 1.25rem;
  }
  .CardAlert__text {
    margin-top: 13px;
    padding-right: 5px;
  }
  .CardAlert__buttons {
    padding-top: 28px;
    padding-right: 5px;
  }
  .CardAlert__buttons ion-button {
    width: auto;
    height: 38px;
  }
  .CardAlert__field {
    margin-top: 36px;
  }
  .CardAlert__field ion-input {
    height: 2.625rem;
    line-height: 2.625rem;
  }
  .CardBlock .CardAlert, .CardUnblock .CardAlert {
    padding: 43px 40px 32px;
  }
  .CardBlock .CardAlert .CardAlert__title,
  .CardBlock .CardAlert .CardAlert__buttons, .CardUnblock .CardAlert .CardAlert__title,
  .CardUnblock .CardAlert .CardAlert__buttons {
    padding-right: 0;
  }
  .CardBlock .CardAlert .CardAlert__text, .CardUnblock .CardAlert .CardAlert__text {
    width: 17rem;
    margin: 12px auto 0;
    padding: 0;
  }
  .CardUnblock .CardAlert .CardAlert__buttons {
    padding-top: 32px;
    padding-left: 0;
  }
  .CardDetail .CardAlert {
    padding: 43px 50px 32px;
  }
  .CardDetail .CardAlert .CardAlert__text {
    margin-top: 13px;
    padding: 0 10px 0 5px;
  }
  .CardDetail .CardAlert .CardAlert__buttons {
    padding-top: 32px;
    padding-right: 5px;
  }
  .CardDetail .CardAlert .CardAlert__buttons ion-button {
    width: 337px;
  }
  .CardLimitDisable .CardAlert {
    padding: 43px 40px 32px;
  }
  .CardLimitDisable .CardAlert .CardAlert__text,
  .CardLimitDisable .CardAlert .CardAlert__title,
  .CardLimitDisable .CardAlert .CardAlert__buttons {
    padding-right: 0;
    padding-left: 0;
  }
  .CardLimitDisable .CardAlert .CardAlert__buttons {
    padding-top: 33px;
  }
  .CardDayLimits .CardAlert__field, .CardMonthLimits .CardAlert__field {
    margin-top: 36px;
  }
  .CardDayLimits .CardAlert__field ion-input, .CardMonthLimits .CardAlert__field ion-input {
    --padding-end: 10px;
  }
  .CardDayLimits .CardAlert .CardAlert__text, .CardMonthLimits .CardAlert .CardAlert__text {
    margin-top: 12px;
  }
  .CardDayLimits .CardAlert, .CardMonthLimits .CardAlert, .CardLimitsConfirmation .CardAlert {
    padding-top: 44px;
  }
  .CardDayLimits .CardAlert .CardAlert__title, .CardMonthLimits .CardAlert .CardAlert__title, .CardLimitsConfirmation .CardAlert .CardAlert__title {
    padding-right: 0;
  }
  .CardDayLimits .CardAlert .CardAlert__buttons, .CardMonthLimits .CardAlert .CardAlert__buttons, .CardLimitsConfirmation .CardAlert .CardAlert__buttons {
    padding-top: 40px;
    padding-right: 0;
  }
  .CardDayLimits .CardAlert .CardAlert__title {
    padding-right: 0;
  }
  .CardDayLimits .CardAlert .CardAlert__text {
    padding-right: 1px;
  }
  .CardLimitsConfirmation .CardAlert {
    padding-left: 40px;
    padding-right: 40px;
  }
  .CardLimitsConfirmation .CardAlert .CardAlert__text {
    margin-top: 12px;
    padding-right: 6px;
  }
  .CardLimitsConfirmation .CardAlert .CardAlert__field {
    padding: 0;
  }
  .CardLimitsConfirmation .CardAlert .CardAlert__field ion-input {
    --padding-start: 3px;
  }
}

ion-searchbar,
.sc-ion-searchbar-md-h,
.sc-ion-searchbar-ios-h {
  padding: 0;
  margin-bottom: 19px;
  --background: rgba(230, 235, 239, 0.4);
  --color-icon: transparent;
  --color: var(--ion-color-black);
  --button-color-clear: transparent;
  --placeholder-color: var(--ion-color-medium);
  --placeholder-font-style: normal;
  --placeholder-font-weight: 400;
  --placeholder-opacity: 1;
}
@media (min-width: 1280px) {
  ion-searchbar,
  .sc-ion-searchbar-md-h,
  .sc-ion-searchbar-ios-h {
    margin-bottom: 14px;
  }
}
ion-searchbar .searchbar-input.sc-ion-searchbar-md,
ion-searchbar .searchbar-input.sc-ion-searchbar-ios,
.sc-ion-searchbar-md-h .searchbar-input.sc-ion-searchbar-md,
.sc-ion-searchbar-md-h .searchbar-input.sc-ion-searchbar-ios,
.sc-ion-searchbar-ios-h .searchbar-input.sc-ion-searchbar-md,
.sc-ion-searchbar-ios-h .searchbar-input.sc-ion-searchbar-ios {
  height: 32px;
  padding-left: 2.1875rem;
  font-size: 0.875rem;
  letter-spacing: -0.4px;
  box-shadow: none;
  border-radius: 0.625rem;
}
@media (min-width: 1280px) {
  ion-searchbar .searchbar-input.sc-ion-searchbar-md,
  ion-searchbar .searchbar-input.sc-ion-searchbar-ios,
  .sc-ion-searchbar-md-h .searchbar-input.sc-ion-searchbar-md,
  .sc-ion-searchbar-md-h .searchbar-input.sc-ion-searchbar-ios,
  .sc-ion-searchbar-ios-h .searchbar-input.sc-ion-searchbar-md,
  .sc-ion-searchbar-ios-h .searchbar-input.sc-ion-searchbar-ios {
    height: 2.25rem;
  }
}
ion-searchbar .searchbar-search-icon.sc-ion-searchbar-md,
ion-searchbar .searchbar-search-icon.sc-ion-searchbar-ios,
.sc-ion-searchbar-md-h .searchbar-search-icon.sc-ion-searchbar-md,
.sc-ion-searchbar-md-h .searchbar-search-icon.sc-ion-searchbar-ios,
.sc-ion-searchbar-ios-h .searchbar-search-icon.sc-ion-searchbar-md,
.sc-ion-searchbar-ios-h .searchbar-search-icon.sc-ion-searchbar-ios {
  top: 50%;
  left: 6px;
  width: 1.75rem;
  height: 1.75rem;
  margin-top: -16px;
  background-image: url(/assets/imgs/search.svg);
  background-size: 1.5625rem auto;
  background-position: center;
  --icon-color: transparent;
}
@media (min-width: 1280px) {
  ion-searchbar .searchbar-search-icon.sc-ion-searchbar-md,
  ion-searchbar .searchbar-search-icon.sc-ion-searchbar-ios,
  .sc-ion-searchbar-md-h .searchbar-search-icon.sc-ion-searchbar-md,
  .sc-ion-searchbar-md-h .searchbar-search-icon.sc-ion-searchbar-ios,
  .sc-ion-searchbar-ios-h .searchbar-search-icon.sc-ion-searchbar-md,
  .sc-ion-searchbar-ios-h .searchbar-search-icon.sc-ion-searchbar-ios {
    left: 0.5rem;
    margin-top: -0.875rem;
  }
}
ion-searchbar .searchbar-clear-button.sc-ion-searchbar-md,
ion-searchbar .searchbar-clear-button.sc-ion-searchbar-ios,
.sc-ion-searchbar-md-h .searchbar-clear-button.sc-ion-searchbar-md,
.sc-ion-searchbar-md-h .searchbar-clear-button.sc-ion-searchbar-ios,
.sc-ion-searchbar-ios-h .searchbar-clear-button.sc-ion-searchbar-md,
.sc-ion-searchbar-ios-h .searchbar-clear-button.sc-ion-searchbar-ios {
  right: 2px;
  width: 1.75rem;
  height: 32px;
  background-image: url(/assets/imgs/close-round.svg);
  background-repeat: no-repeat;
  background-size: 0.875rem auto;
  background-position: center;
}
@media (min-width: 1280px) {
  ion-searchbar .searchbar-clear-button.sc-ion-searchbar-md,
  ion-searchbar .searchbar-clear-button.sc-ion-searchbar-ios,
  .sc-ion-searchbar-md-h .searchbar-clear-button.sc-ion-searchbar-md,
  .sc-ion-searchbar-md-h .searchbar-clear-button.sc-ion-searchbar-ios,
  .sc-ion-searchbar-ios-h .searchbar-clear-button.sc-ion-searchbar-md,
  .sc-ion-searchbar-ios-h .searchbar-clear-button.sc-ion-searchbar-ios {
    right: 0.375rem;
    height: 100%;
  }
}
ion-searchbar .searchbar-clear-button.sc-ion-searchbar-md .searchbar-clear-icon.sc-ion-searchbar-md,
ion-searchbar .searchbar-clear-button.sc-ion-searchbar-md .searchbar-clear-icon.sc-ion-searchbar-ios,
ion-searchbar .searchbar-clear-button.sc-ion-searchbar-ios .searchbar-clear-icon.sc-ion-searchbar-md,
ion-searchbar .searchbar-clear-button.sc-ion-searchbar-ios .searchbar-clear-icon.sc-ion-searchbar-ios,
.sc-ion-searchbar-md-h .searchbar-clear-button.sc-ion-searchbar-md .searchbar-clear-icon.sc-ion-searchbar-md,
.sc-ion-searchbar-md-h .searchbar-clear-button.sc-ion-searchbar-md .searchbar-clear-icon.sc-ion-searchbar-ios,
.sc-ion-searchbar-md-h .searchbar-clear-button.sc-ion-searchbar-ios .searchbar-clear-icon.sc-ion-searchbar-md,
.sc-ion-searchbar-md-h .searchbar-clear-button.sc-ion-searchbar-ios .searchbar-clear-icon.sc-ion-searchbar-ios,
.sc-ion-searchbar-ios-h .searchbar-clear-button.sc-ion-searchbar-md .searchbar-clear-icon.sc-ion-searchbar-md,
.sc-ion-searchbar-ios-h .searchbar-clear-button.sc-ion-searchbar-md .searchbar-clear-icon.sc-ion-searchbar-ios,
.sc-ion-searchbar-ios-h .searchbar-clear-button.sc-ion-searchbar-ios .searchbar-clear-icon.sc-ion-searchbar-md,
.sc-ion-searchbar-ios-h .searchbar-clear-button.sc-ion-searchbar-ios .searchbar-clear-icon.sc-ion-searchbar-ios {
  display: none;
}
ion-searchbar.searchbar-has-value .searchbar-clear-button.sc-ion-searchbar-md,
ion-searchbar.searchbar-has-value .searchbar-clear-button.sc-ion-searchbar-ios,
.sc-ion-searchbar-md-h.searchbar-has-value .searchbar-clear-button.sc-ion-searchbar-md,
.sc-ion-searchbar-md-h.searchbar-has-value .searchbar-clear-button.sc-ion-searchbar-ios,
.sc-ion-searchbar-ios-h.searchbar-has-value .searchbar-clear-button.sc-ion-searchbar-md,
.sc-ion-searchbar-ios-h.searchbar-has-value .searchbar-clear-button.sc-ion-searchbar-ios {
  display: block;
}

.sc-ion-searchbar-ios-h {
  height: 36px;
  padding: 0 10px 0 8px;
}
@media (min-width: 1280px) {
  .sc-ion-searchbar-ios-h {
    height: 60px;
    padding: 0;
  }
}
.sc-ion-searchbar-ios-h.searchbar-left-aligned .searchbar-input.sc-ion-searchbar-ios {
  -webkit-padding-start: 35px;
  padding-inline-start: 35px;
}
@media (min-width: 1280px) {
  .sc-ion-searchbar-ios-h.searchbar-left-aligned .searchbar-input.sc-ion-searchbar-ios {
    -webkit-padding-start: 30px;
    padding-inline-start: 30px;
  }
}

.loaging {
  width: 1rem;
  height: 1rem;
  display: inline-block;
  vertical-align: middle;
}
.loaging svg {
  width: 100%;
  height: 100%;
}
.loaging svg path {
  fill: var(--ion-color-white);
  animation: lds-spinner 1.1s linear infinite;
}
.loaging svg path:nth-child(1) {
  animation-delay: 0s;
}
.loaging svg path:nth-child(2) {
  animation-delay: 0.1s;
}
.loaging svg path:nth-child(3) {
  animation-delay: 0.2s;
}
.loaging svg path:nth-child(4) {
  animation-delay: 0.3s;
}
.loaging svg path:nth-child(5) {
  animation-delay: 0.4s;
}
.loaging svg path:nth-child(6) {
  animation-delay: 0.5s;
}
.loaging svg path:nth-child(7) {
  animation-delay: 0.6s;
}
.loaging svg path:nth-child(8) {
  animation-delay: 0.7s;
}
.loaging svg path:nth-child(9) {
  animation-delay: 0.8s;
}
.loaging svg path:nth-child(10) {
  animation-delay: 0.9s;
}
.loaging svg path:nth-child(11) {
  animation-delay: 1s;
}
.loaging svg path:nth-child(12) {
  animation-delay: 1.1s;
}

@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  9% {
    opacity: 0.95;
  }
  18% {
    opacity: 0.9;
  }
  27% {
    opacity: 0.8;
  }
  36% {
    opacity: 0.6;
  }
  45% {
    opacity: 0.55;
  }
  54% {
    opacity: 0.5;
  }
  63% {
    opacity: 0.45;
  }
  72% {
    opacity: 0.4;
  }
  81% {
    opacity: 0.3;
  }
  90% {
    opacity: 0.25;
  }
  100% {
    opacity: 0.2;
  }
}
.checkbox {
  margin: 1.4375rem 0;
  color: var(--ion-color-medium);
  display: flex;
  align-items: center;
}
.checkbox a {
  font-weight: 600;
  text-decoration: underline;
  color: var(--ion-color-medium);
}
.checkbox a:hover, .checkbox a:focus, .checkbox a:active {
  text-decoration: none;
  color: var(--ion-color-medium);
}
.checkbox ion-label {
  font-size: 0.625rem;
  margin: 0;
}

ion-checkbox {
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  min-width: 1.5rem;
  margin: 0 0.75rem 0 0;
  --size: 0;
  --border-width: 0;
  --background-checked: transparent;
  --ion-color-success-contrast: var(--ion-color-white);
  border: 1px solid transparent;
  border-radius: 0.1875rem;
}
ion-checkbox:after {
  content: "";
  position: absolute;
  top: -1px;
  left: -1px;
  display: block;
  width: 22px;
  height: 22px;
  border-radius: 0.1875rem;
  border: 1px solid var(--ion-color-medium);
  background-color: var(--ion-color-white);
  pointer-events: none;
}
ion-checkbox.checkbox-checked {
  border: 1px solid transparent;
}
ion-checkbox.checkbox-checked:after {
  width: 24px;
  height: 24px;
  background-image: url(/assets/imgs/checkbox-checked.svg);
  background-repeat: no-repeat;
  background-position: center;
  border: transparent;
}

ion-button {
  position: relative;
  margin: 0;
  --margin-top: 0;
  --margin-bottom: 0;
  --margin-end: 0;
  --margin-start: 0;
  --padding-start: 2.75rem;
  --padding-end: 2.75rem;
  font-weight: 600;
  letter-spacing: -0.1px;
  text-transform: none;
}
ion-button ion-icon {
  position: absolute;
  top: 50%;
  left: -2rem;
  margin-top: -0.875rem;
}
ion-button[size=large] {
  height: 52px;
}

ion-toggle {
  flex-shrink: 0;
  width: 36px;
  height: 14px;
  position: relative;
  padding: 4px 0;
  margin-left: 12px;
  --background: transparent;
  --background-checked: transparent;
  --handle-background: transparent;
  --handle-background-checked: transparent;
  background-color: var(--ion-color-white);
  border-radius: 14px;
  border: 1px solid rgba(182, 188, 196, 0.25);
}
@media (min-width: 1280px) {
  ion-toggle {
    width: 44px;
    padding-top: 6px;
    padding-bottom: 6px;
  }
}
ion-toggle:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  transition: background-color 0.16s;
  background-color: var(--ion-color-white);
  border-radius: 14px;
}
ion-toggle:after {
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  z-index: 11;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: var(--ion-color-white);
  box-shadow: 0 3px 1px 0 rgba(0, 0, 0, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0.16), 0 3px 8px 0 rgba(0, 0, 0, 0.15);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: background-color, -webkit-transform, transform;
  transition-property: background-color, -webkit-transform, transform;
  will-change: transform, background-color;
}
@media (min-width: 1280px) {
  ion-toggle:after {
    width: 24px;
    height: 24px;
  }
}
ion-toggle.toggle-checked {
  border-color: var(--ion-color-success);
  background-color: var(--ion-color-success);
}
ion-toggle.toggle-checked:before {
  background-color: var(--ion-color-success);
}
ion-toggle.toggle-checked:after {
  -webkit-transform: translate3d(14px, 0, 0);
  transform: translate3d(14px, 0, 0);
}
@media (min-width: 1280px) {
  ion-toggle.toggle-checked:after {
    -webkit-transform: translate3d(18px, 0, 0);
    transform: translate3d(18px, 0, 0);
  }
}

ion-textarea {
  --placeholder-color: var(--ion-color-medium);
  --placeholder-font-style: normal;
  --placeholder-font-weight: 400;
  --placeholder-opacity: 1;
  font-size: 0.8125rem;
  line-height: 1rem;
  letter-spacing: -0.2px;
}

textarea {
  height: 100%;
  overflow-y: scroll;
}

ion-datetime {
  padding: 0;
  background-repeat: no-repeat;
  background-position: right center;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 22px;
  letter-spacing: normal;
  --placeholder-color: var(--ion-color-medium);
}

ion-select {
  position: relative;
}

ion-popover ion-backdrop {
  opacity: 0 !important;
  --ion-backdrop-color: transparent;
}
ion-popover.popover--money-account-property {
  --box-shadow: none;
  --width: auto;
  --height: auto;
  --max-height: none;
}
ion-popover.popover--money-account-property .popover-content {
  margin-top: -5px;
  margin-left: -154px;
  border: 1px solid var(--ion-color-gray);
  border-radius: 12px;
}
@media (min-width: 1280px) {
  ion-popover.popover--money-account-property .popover-content {
    margin-top: 1.625rem;
    margin-left: 0;
  }
}
ion-popover.popover-history-filter {
  /*&.popover-start {
    .popover-wrapper {
      .popover-content {
        margin-left: -18px;
        &:after, &:before { left: 50%; }
        &:after { margin-left: -10px; }
        &:before { margin-left: -11px; }
      }
    }
  }
  &.popover-end {
    .popover-wrapper {
      .popover-content {
        margin-left: 12px;
        &:after, &:before { right: 11px; }
        &:after { right: 12px; }
        &:before {  }
      }
    }
  }*/
}
ion-popover.popover-history-filter .popover-wrapper .popover-content {
  width: 282px;
  border-radius: 0;
  box-shadow: none;
  border: none;
  background: transparent;
  overflow: visible;
}
ion-popover.popover-history-filter .popover-wrapper .popover-viewport {
  width: 100%;
  height: 100%;
  padding: 11px 0;
  border-radius: 15px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.12);
  border: 1px solid var(--ion-color-border);
  background-color: var(--ion-color-white);
  overflow: hidden;
}
@media (min-width: 1280px) {
  ion-popover.popover-history-filter .popover-wrapper .popover-viewport {
    padding: 11px 7px;
  }
}
ion-popover:not(.popover--money-account-property):not(.popover-history-filter):not(.Registration-select) .popover-wrapper .popover-content {
  width: 199px;
  margin-top: 0;
  margin-left: 20px;
  box-shadow: none;
  border-radius: 0 0 0.3125rem 0.3125rem;
  border: 0.5px solid var(--ion-color-border);
  border-top: 1px solid var(--ion-color-success);
  background-color: var(--ion-color-white);
}
ion-popover:not(.popover--money-account-property):not(.popover-history-filter):not(.Registration-select) .popover-wrapper .popover-content ion-list,
ion-popover:not(.popover--money-account-property):not(.popover-history-filter):not(.Registration-select) .popover-wrapper .popover-content .list-md,
ion-popover:not(.popover--money-account-property):not(.popover-history-filter):not(.Registration-select) .popover-wrapper .popover-content .list-ios {
  margin: 0;
  padding: 0;
}
ion-popover:not(.popover--money-account-property):not(.popover-history-filter):not(.Registration-select) .popover-wrapper .popover-content ion-list .item,
ion-popover:not(.popover--money-account-property):not(.popover-history-filter):not(.Registration-select) .popover-wrapper .popover-content .list-md .item,
ion-popover:not(.popover--money-account-property):not(.popover-history-filter):not(.Registration-select) .popover-wrapper .popover-content .list-ios .item {
  --min-height: 36px;
  --border-width: 0;
  --border-color: transparent;
}
ion-popover:not(.popover--money-account-property):not(.popover-history-filter):not(.Registration-select) .popover-wrapper .popover-content ion-list .item:not(:last-child),
ion-popover:not(.popover--money-account-property):not(.popover-history-filter):not(.Registration-select) .popover-wrapper .popover-content .list-md .item:not(:last-child),
ion-popover:not(.popover--money-account-property):not(.popover-history-filter):not(.Registration-select) .popover-wrapper .popover-content .list-ios .item:not(:last-child) {
  border-bottom: 1px solid var(--ion-color-border);
}
ion-popover:not(.popover--money-account-property):not(.popover-history-filter):not(.Registration-select) .popover-wrapper .popover-content ion-list .item ion-label,
ion-popover:not(.popover--money-account-property):not(.popover-history-filter):not(.Registration-select) .popover-wrapper .popover-content .list-md .item ion-label,
ion-popover:not(.popover--money-account-property):not(.popover-history-filter):not(.Registration-select) .popover-wrapper .popover-content .list-ios .item ion-label {
  margin: 0;
  padding-right: 1.5rem;
  width: 100%;
  font-size: 0.8125rem;
  letter-spacing: -0.08px;
  text-align: right;
}
ion-popover:not(.popover--money-account-property):not(.popover-history-filter):not(.Registration-select) .popover-wrapper .popover-content ion-list .item ion-radio,
ion-popover:not(.popover--money-account-property):not(.popover-history-filter):not(.Registration-select) .popover-wrapper .popover-content .list-md .item ion-radio,
ion-popover:not(.popover--money-account-property):not(.popover-history-filter):not(.Registration-select) .popover-wrapper .popover-content .list-ios .item ion-radio {
  width: 100%;
  height: 100%;
  left: 0;
  margin: 0;
  opacity: 0;
  position: absolute;
}
ion-popover:not(.popover--money-account-property):not(.popover-history-filter):not(.Registration-select) .popover-wrapper .popover-content ion-list .item:hover, ion-popover:not(.popover--money-account-property):not(.popover-history-filter):not(.Registration-select) .popover-wrapper .popover-content ion-list .item.item-radio-checked,
ion-popover:not(.popover--money-account-property):not(.popover-history-filter):not(.Registration-select) .popover-wrapper .popover-content .list-md .item:hover,
ion-popover:not(.popover--money-account-property):not(.popover-history-filter):not(.Registration-select) .popover-wrapper .popover-content .list-md .item.item-radio-checked,
ion-popover:not(.popover--money-account-property):not(.popover-history-filter):not(.Registration-select) .popover-wrapper .popover-content .list-ios .item:hover,
ion-popover:not(.popover--money-account-property):not(.popover-history-filter):not(.Registration-select) .popover-wrapper .popover-content .list-ios .item.item-radio-checked {
  background-color: #eef4eb;
}
@media (min-width: 1280px) {
  ion-popover:not(.popover--money-account-property):not(.popover-history-filter):not(.Registration-select) .popover-wrapper .popover-content {
    width: 13.875rem;
    margin-top: 0;
    margin-left: 16px;
  }
  ion-popover:not(.popover--money-account-property):not(.popover-history-filter):not(.Registration-select) .popover-wrapper .popover-content ion-list .item,
  ion-popover:not(.popover--money-account-property):not(.popover-history-filter):not(.Registration-select) .popover-wrapper .popover-content .list-md .item,
  ion-popover:not(.popover--money-account-property):not(.popover-history-filter):not(.Registration-select) .popover-wrapper .popover-content .list-ios .item {
    --min-height: 2.625rem;
  }
}
ion-popover:not(.popover--money-account-property):not(.popover-history-filter):not(.Registration-select).SelectCurrency .popover-wrapper .popover-content, ion-popover:not(.popover--money-account-property):not(.popover-history-filter):not(.Registration-select).SelectCurrencyMax .popover-wrapper .popover-content {
  width: 56px;
  margin-top: 0;
  margin-top: -27px;
  margin-left: -7px;
  border-radius: 0.125rem;
  border-top: 0.5px solid var(--ion-color-border);
}
ion-popover:not(.popover--money-account-property):not(.popover-history-filter):not(.Registration-select).SelectCurrency .popover-wrapper .popover-content ion-list .item,
ion-popover:not(.popover--money-account-property):not(.popover-history-filter):not(.Registration-select).SelectCurrency .popover-wrapper .popover-content .list-md .item,
ion-popover:not(.popover--money-account-property):not(.popover-history-filter):not(.Registration-select).SelectCurrency .popover-wrapper .popover-content .list-ios .item, ion-popover:not(.popover--money-account-property):not(.popover-history-filter):not(.Registration-select).SelectCurrencyMax .popover-wrapper .popover-content ion-list .item,
ion-popover:not(.popover--money-account-property):not(.popover-history-filter):not(.Registration-select).SelectCurrencyMax .popover-wrapper .popover-content .list-md .item,
ion-popover:not(.popover--money-account-property):not(.popover-history-filter):not(.Registration-select).SelectCurrencyMax .popover-wrapper .popover-content .list-ios .item {
  --min-height: 2.1875rem;
  --padding-start: 0.4375rem;
}
ion-popover:not(.popover--money-account-property):not(.popover-history-filter):not(.Registration-select).SelectCurrency .popover-wrapper .popover-content ion-list .item ion-label,
ion-popover:not(.popover--money-account-property):not(.popover-history-filter):not(.Registration-select).SelectCurrency .popover-wrapper .popover-content .list-md .item ion-label,
ion-popover:not(.popover--money-account-property):not(.popover-history-filter):not(.Registration-select).SelectCurrency .popover-wrapper .popover-content .list-ios .item ion-label, ion-popover:not(.popover--money-account-property):not(.popover-history-filter):not(.Registration-select).SelectCurrencyMax .popover-wrapper .popover-content ion-list .item ion-label,
ion-popover:not(.popover--money-account-property):not(.popover-history-filter):not(.Registration-select).SelectCurrencyMax .popover-wrapper .popover-content .list-md .item ion-label,
ion-popover:not(.popover--money-account-property):not(.popover-history-filter):not(.Registration-select).SelectCurrencyMax .popover-wrapper .popover-content .list-ios .item ion-label {
  padding: 0;
  text-align: left;
  font-size: 0.6875rem;
  font-weight: 600;
}
ion-popover:not(.popover--money-account-property):not(.popover-history-filter):not(.Registration-select).SelectCurrencyMax .popover-wrapper .popover-content {
  width: 170px;
  margin-top: -8px;
  margin-left: -5px;
}
ion-popover:not(.popover--money-account-property):not(.popover-history-filter):not(.Registration-select).SelectCurrencyMax .popover-wrapper .popover-content:after {
  right: 12px;
  background-image: url(/assets/imgs/arrow-up-small.svg);
}
ion-popover:not(.popover--money-account-property):not(.popover-history-filter):not(.Registration-select).SelectCurrencyMax .popover-wrapper .popover-content ion-list .item,
ion-popover:not(.popover--money-account-property):not(.popover-history-filter):not(.Registration-select).SelectCurrencyMax .popover-wrapper .popover-content .list-md .item,
ion-popover:not(.popover--money-account-property):not(.popover-history-filter):not(.Registration-select).SelectCurrencyMax .popover-wrapper .popover-content .list-ios .item {
  --inner-padding-end: 29px;
}
ion-popover:not(.popover--money-account-property):not(.popover-history-filter):not(.Registration-select).SelectCurrencyMax .popover-wrapper .popover-content ion-list .item ion-label,
ion-popover:not(.popover--money-account-property):not(.popover-history-filter):not(.Registration-select).SelectCurrencyMax .popover-wrapper .popover-content .list-md .item ion-label,
ion-popover:not(.popover--money-account-property):not(.popover-history-filter):not(.Registration-select).SelectCurrencyMax .popover-wrapper .popover-content .list-ios .item ion-label {
  color: var(--ion-color-success);
  text-align: right;
}
ion-popover:not(.popover--money-account-property):not(.popover-history-filter):not(.Registration-select).AccountCurrency .popover-wrapper .popover-content, ion-popover:not(.popover--money-account-property):not(.popover-history-filter):not(.Registration-select).HistoryFilterTotal .popover-wrapper .popover-content {
  width: 230px;
  margin-top: 0;
  border-radius: 0.875rem;
  border-top: 0.5px solid var(--ion-color-border);
  margin: 0 0px 0 -4px;
}
ion-popover:not(.popover--money-account-property):not(.popover-history-filter):not(.Registration-select).AccountCurrency .popover-wrapper .popover-content:after, ion-popover:not(.popover--money-account-property):not(.popover-history-filter):not(.Registration-select).HistoryFilterTotal .popover-wrapper .popover-content:after {
  content: "";
  position: absolute;
  top: 1px;
  right: 15px;
  width: 0.5625rem;
  height: 28px;
  background-image: url(/assets/imgs/arrow-up-small.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 28px auto;
  transition: all 0.25s;
}
ion-popover:not(.popover--money-account-property):not(.popover-history-filter):not(.Registration-select).AccountCurrency .popover-wrapper .popover-content ion-list .item,
ion-popover:not(.popover--money-account-property):not(.popover-history-filter):not(.Registration-select).AccountCurrency .popover-wrapper .popover-content .list-md .item,
ion-popover:not(.popover--money-account-property):not(.popover-history-filter):not(.Registration-select).AccountCurrency .popover-wrapper .popover-content .list-ios .item, ion-popover:not(.popover--money-account-property):not(.popover-history-filter):not(.Registration-select).HistoryFilterTotal .popover-wrapper .popover-content ion-list .item,
ion-popover:not(.popover--money-account-property):not(.popover-history-filter):not(.Registration-select).HistoryFilterTotal .popover-wrapper .popover-content .list-md .item,
ion-popover:not(.popover--money-account-property):not(.popover-history-filter):not(.Registration-select).HistoryFilterTotal .popover-wrapper .popover-content .list-ios .item {
  --min-height: 1.75rem;
  border: none !important;
  padding-left: 3px;
}
ion-popover:not(.popover--money-account-property):not(.popover-history-filter):not(.Registration-select).AccountCurrency .popover-wrapper .popover-content ion-list .item ion-label,
ion-popover:not(.popover--money-account-property):not(.popover-history-filter):not(.Registration-select).AccountCurrency .popover-wrapper .popover-content .list-md .item ion-label,
ion-popover:not(.popover--money-account-property):not(.popover-history-filter):not(.Registration-select).AccountCurrency .popover-wrapper .popover-content .list-ios .item ion-label, ion-popover:not(.popover--money-account-property):not(.popover-history-filter):not(.Registration-select).HistoryFilterTotal .popover-wrapper .popover-content ion-list .item ion-label,
ion-popover:not(.popover--money-account-property):not(.popover-history-filter):not(.Registration-select).HistoryFilterTotal .popover-wrapper .popover-content .list-md .item ion-label,
ion-popover:not(.popover--money-account-property):not(.popover-history-filter):not(.Registration-select).HistoryFilterTotal .popover-wrapper .popover-content .list-ios .item ion-label {
  padding: 0;
  font-size: 0.75rem;
  letter-spacing: 0;
  text-align: center;
}
@media (min-width: 1280px) {
  ion-popover:not(.popover--money-account-property):not(.popover-history-filter):not(.Registration-select).AccountCurrency .popover-wrapper .popover-content, ion-popover:not(.popover--money-account-property):not(.popover-history-filter):not(.Registration-select).HistoryFilterTotal .popover-wrapper .popover-content {
    width: 265px;
    margin: -1px 0 0 -1px;
  }
}
@media (min-width: 1440px) {
  ion-popover:not(.popover--money-account-property):not(.popover-history-filter):not(.Registration-select).AccountCurrency .popover-wrapper .popover-content, ion-popover:not(.popover--money-account-property):not(.popover-history-filter):not(.Registration-select).HistoryFilterTotal .popover-wrapper .popover-content {
    width: 317px;
  }
}
ion-popover:not(.popover--money-account-property):not(.popover-history-filter):not(.Registration-select).SelectCountry .popover-wrapper .popover-content {
  width: 230px;
  margin-left: 0;
  margin-top: 14px;
}
ion-popover:not(.popover--money-account-property):not(.popover-history-filter):not(.Registration-select).SelectCountry .popover-wrapper .popover-content ion-list .item ion-label,
ion-popover:not(.popover--money-account-property):not(.popover-history-filter):not(.Registration-select).SelectCountry .popover-wrapper .popover-content .list-md .item ion-label,
ion-popover:not(.popover--money-account-property):not(.popover-history-filter):not(.Registration-select).SelectCountry .popover-wrapper .popover-content .list-ios .item ion-label {
  padding: 0;
}
@media (min-width: 1280px) {
  ion-popover:not(.popover--money-account-property):not(.popover-history-filter):not(.Registration-select).SelectCountry .popover-wrapper .popover-content {
    width: 230px;
    margin-left: 0;
    margin-top: 14px;
  }
  ion-popover:not(.popover--money-account-property):not(.popover-history-filter):not(.Registration-select).SelectCountry .popover-wrapper .popover-content ion-list .item ion-label,
  ion-popover:not(.popover--money-account-property):not(.popover-history-filter):not(.Registration-select).SelectCountry .popover-wrapper .popover-content .list-md .item ion-label,
  ion-popover:not(.popover--money-account-property):not(.popover-history-filter):not(.Registration-select).SelectCountry .popover-wrapper .popover-content .list-ios .item ion-label {
    padding: 0;
  }
}
ion-popover:not(.popover--money-account-property):not(.popover-history-filter):not(.Registration-select).SelectTemplateDescription .popover-wrapper .popover-content {
  width: 261px;
  margin: 2px 0 0 4px;
}
ion-popover.Registration-select .popover-wrapper .popover-content {
  width: 582px;
  padding: 27px 25px;
  border-radius: 20px;
  background-color: var(--ion-color-white);
}
ion-popover.Registration-select .popover-wrapper .popover-content ion-list,
ion-popover.Registration-select .popover-wrapper .popover-content .list-md,
ion-popover.Registration-select .popover-wrapper .popover-content .list-ios {
  margin: 0;
  padding: 0;
}
ion-popover.Registration-select .popover-wrapper .popover-content ion-list .item,
ion-popover.Registration-select .popover-wrapper .popover-content .list-md .item,
ion-popover.Registration-select .popover-wrapper .popover-content .list-ios .item {
  --min-height: auto;
  --border-width: 0;
  --border-color: transparent;
  --background: transparent;
  margin-bottom: 25px;
  color: var(--ion-color-black);
}
ion-popover.Registration-select .popover-wrapper .popover-content ion-list .item ion-label,
ion-popover.Registration-select .popover-wrapper .popover-content .list-md .item ion-label,
ion-popover.Registration-select .popover-wrapper .popover-content .list-ios .item ion-label {
  margin: 0;
  padding: 0;
  width: 100%;
  font-size: 15px;
  letter-spacing: 1.07px;
}
ion-popover.Registration-select .popover-wrapper .popover-content ion-list .item ion-radio,
ion-popover.Registration-select .popover-wrapper .popover-content .list-md .item ion-radio,
ion-popover.Registration-select .popover-wrapper .popover-content .list-ios .item ion-radio {
  width: 100%;
  height: 100%;
  left: 0;
  margin: 0;
  opacity: 0;
  position: absolute;
}
ion-popover.Registration-select .popover-wrapper .popover-content ion-list .item:hover, ion-popover.Registration-select .popover-wrapper .popover-content ion-list .item.item-radio-checked,
ion-popover.Registration-select .popover-wrapper .popover-content .list-md .item:hover,
ion-popover.Registration-select .popover-wrapper .popover-content .list-md .item.item-radio-checked,
ion-popover.Registration-select .popover-wrapper .popover-content .list-ios .item:hover,
ion-popover.Registration-select .popover-wrapper .popover-content .list-ios .item.item-radio-checked {
  color: var(--ion-color-success);
}

.cryptoSettingsPopover {
  --min-width: 300px;
}
.cryptoSettingsPopover .popover-wrapper .popover-content {
  border-top-color: var(--ion-color-border) !important;
  margin-left: initial !important;
}

.swiper-pagination-bullet-active {
  background: var(--bullet-background-active) !important;
}

.swiper-pagination-bullet {
  width: 6px !important;
  height: 6px !important;
}

.RCComponent {
  padding: 0;
  margin: 0;
  background-color: transparent;
}
.RCComponent .title {
  height: 1.375rem;
  overflow: visible;
  position: relative;
  margin-bottom: 0.78125rem;
  padding: 0;
  text-align: center;
}
.ExportStatements .RCComponent .title {
  margin-bottom: 12px;
}
@media (min-width: 1440px) {
  .ExportStatements .RCComponent .title {
    padding-right: 10px;
  }
}
.RCComponent .title .switch-btn {
  width: 10rem;
  height: 1.375rem;
  min-height: 1.375rem;
  font-size: 0.6875rem;
  line-height: 1.375rem;
  color: var(--ion-color-dark);
  border-radius: 0.9375rem;
  background-color: transparent;
  border: 0.5px solid var(--ion-color-gray);
  float: none;
  display: inline-block;
  vertical-align: top;
}
.ExportStatements .RCComponent .title .switch-btn {
  min-height: 21px;
  height: 21px;
  line-height: 21px;
}
.RCComponent .title .back,
.RCComponent .title .forward {
  position: absolute;
  top: 0;
  width: auto;
  height: auto;
  min-height: auto;
  margin: 0;
  --padding-end: 0;
  --padding-start: 0;
}
.RCComponent .title .back ion-icon,
.RCComponent .title .forward ion-icon {
  position: static;
  width: 1.75rem;
  height: 1.25rem;
  margin: 0;
  font-size: 0;
  color: transparent;
  background-position: center;
  background-repeat: no-repeat;
}
.RCComponent .title .back {
  left: 0;
}
.RCComponent .title .back ion-icon {
  background-image: url(/assets/imgs/arrow-left-small-green.svg);
}
.RCComponent .title .forward {
  right: 0;
}
.RCComponent .title .forward ion-icon {
  background-image: url(/assets/imgs/arrow-right-small-green.svg);
}
.RCComponent.RCComponent-curmonthactive .switch-btn {
  background: var(--ion-color-success);
  color: var(--ion-color-white);
  border-color: var(--ion-color-success);
}
.RCComponent .week-toolbar {
  --background: transparent;
  --min-height: auto;
}
.RCComponent .week-title.transparent {
  height: auto;
  padding: 0;
  margin: 0;
  color: var(--ion-color-dark);
  font-size: 0.75rem;
  line-height: 1.8125rem;
}
.RCComponent .primary .days .last-month-day p,
.RCComponent .primary .days .next-month-day p {
  color: var(--ion-color-gray);
}
.RCComponent .primary .days .last-month-day.on-selected p,
.RCComponent .primary .days .next-month-day.on-selected p {
  color: var(--ion-color-white);
}
.RCComponent .primary .days.between .next-month-day p {
  color: var(--ion-color-white);
}
.RCComponent .primary .days .today p,
.RCComponent .primary .days .marked p,
.RCComponent .primary button.days-btn small {
  color: var(--ion-color-success);
}
.RCComponent .primary .endSelection button.days-btn p {
  color: var(--ion-color-white) !important;
}
.RCComponent .primary .endSelection:after,
.RCComponent .primary .startSelection:after {
  display: none;
}
.RCComponent .primary .between button.days-btn {
  background: rgba(119, 164, 92, 0.5);
}
.RCComponent .primary .days .on-selected,
.RCComponent .primary .endSelection button.days-btn,
.RCComponent .primary .startSelection button.days-btn {
  background: var(--ion-color-success);
}
.RCComponent .primary button {
  border-radius: 0 !important;
}
.RCComponent .primary div[class=days] button.on-selected,
.RCComponent .primary div[class="days is-last-wrap"] button.on-selected,
.RCComponent .primary div[class="days is-first-wrap"] button.on-selected {
  border-radius: 1.8125rem !important;
}
.RCComponent .primary .startSelection button.days-btn {
  border-top-left-radius: 1.8125rem !important;
  border-bottom-left-radius: 1.8125rem !important;
}
.RCComponent .primary .endSelection button.days-btn {
  border-top-right-radius: 1.8125rem !important;
  border-bottom-right-radius: 1.8125rem !important;
}

.popover-history-filter .RCComponent .title .switch-btn {
  font-weight: 600;
  font-size: 15px;
  color: var(--ion-color-dark);
  border: none;
  background: transparent;
}
.popover-history-filter .RCComponent .title .back, .popover-history-filter .RCComponent .title .forward {
  top: 2px;
}

.bcc-button {
  position: relative;
  width: 100%;
  max-width: 270px;
  height: 35px;
  line-height: 30px;
  border-radius: 26px;
  color: var(--ion-color-white);
  font-family: SFProText;
  font-size: 13px;
  font-weight: 600;
  text-align: center;
}
.bcc-button_md {
  height: 44px;
}
.bcc-button_md {
  height: 44px;
}
.bcc-button_large {
  width: 316px;
  height: 52px;
  font-size: 15px;
}
.bcc-button:focus {
  outline: none;
}
.bcc-button ion-icon {
  position: absolute;
  right: 10px;
}
.bcc-button.bcc-button-green {
  background-color: var(--ion-color-success);
}
.bcc-button.bcc-button-white {
  background-color: var(--ion-color-success);
}
.bcc-button.bcc-button-outline {
  background-color: transparent;
}
.bcc-button.bcc-button-outline.bcc-button-green {
  color: var(--ion-color-success);
  border: 2px solid var(--ion-color-success);
}
.bcc-button.bcc-button-outline.bcc-button-green ion-skeleton-text {
  background-color: var(--ion-color-success);
}
.bcc-button.bcc-button-outline.bcc-button-white {
  color: var(--ion-color-white);
  border: 2px solid var(--ion-color-white);
}
.bcc-button.bcc-button-outline.bcc-button-white ion-skeleton-text {
  background-color: var(--ion-color-white);
}

.bcc-button-small {
  position: relative;
  width: 100%;
  max-width: 270px;
  height: 35px;
  line-height: 24px;
  border-radius: 26px;
  color: var(--ion-color-white);
  font-family: SFProText;
  font-size: 13px;
  font-weight: 600;
  text-align: center;
}
.bcc-button-small:focus {
  outline: none;
}
.bcc-button-small ion-icon {
  position: absolute;
  right: 6px;
}
.bcc-button-small.bcc-button-green {
  background-color: var(--ion-color-success);
}
.bcc-button-small.bcc-button-white {
  background-color: var(--ion-color-success);
}
.bcc-button-small.bcc-button-outline {
  background-color: transparent;
}
.bcc-button-small.bcc-button-outline.bcc-button-green {
  color: var(--ion-color-success);
  border: 2px solid var(--ion-color-success);
}
.bcc-button-small.bcc-button-outline.bcc-button-white {
  color: var(--ion-color-white);
  border: 2px solid var(--ion-color-white);
}

.client-select-small .isSelected .select-custom .label-wrap ion-chip,
.client-select--filter .isSelected .select-custom .label-wrap ion-chip {
  width: 100%;
  background-color: transparent;
}

/*
.Authorization {
	&.Bcc {
		background-color: var(--ion-color-black);
		background-image: url(../assets/imgs/cat-bg@2x.png);
		background-size: 300px auto;
		background-repeat: no-repeat;
		background-position: center 60px;

		@media(min-width: 1200px) {
			background-size: 400px auto;
			background-position: center 60px;
			// padding: 180px 20px 20px;
		}
		@media(min-width: 1440px) {
			background-position: center 40px;
			// padding: 192px 20px 20px;
		}
	}

	&.Gekkard {
		background-color: rgb(31, 30, 96);
		background-image: url(../assets/gekkard/gekkard-logo@2x.png);
		background-size: 300px auto;
		background-repeat: no-repeat;
		background-position: center 200px;
	}

	color: var(--ion-color-white);
	align-items: center;
	justify-content: center;
	padding: 162px 20px 20px;

	ion-select:after {
		background-color: var(--ion-color-black);
		background-image: url(../assets/imgs/arrow-down-small-white.svg);
	}

	&-container {
		width: 250px;
		@media(min-width: 1200px) {
			width: 270px;
		}
	}

	&-logo {
		 margin-bottom: 33px;
		 svg { width: 200px; height: auto; }

		 @media(min-width: 1200px) {
			 margin-bottom: 35px;
			 svg { width: 275px; height: auto; }
		 }
		 @media(min-width: 1440px) {
			 margin-bottom: 50px;
		 }
	 }

	&-form {

		.error-label {
			position: absolute;
			left: 60px; top: 4px;
			color: var(--ion-color-danger);
			display: none;
			font-size: 13px;
			line-height: 15px;
		}

		&__row {
			position: relative;
			width: 100%;
			min-height: 63px;
			padding: 4px 0 14px;
			border-bottom: 1px solid rgba(255, 255, 255, 0.5);
			& + .Authorization-form__row { margin-top: 10px; }

			@media(min-width: 1280px) {
				& + .Authorization-form__row { margin-top: 15px; }
			}

			ion-label {
				display: block;
				margin: 0 0 8px;
				font-size: 13px;
				line-height: 15px;
				color: rgba(255, 255, 255, 0.5);
			}
			input,
			ion-input {
				--padding-end: 0;
				--padding-start: 0;
				height: 22px;
				line-height: 22px;
				font-size: 15px;
				font-weight: 400;
				background: transparent;
				border: none;
				padding-left: 15px;

				&[name="code"],
				&[name="password"] {
					color: rgba(255, 255, 255, 0.5);
				}
				&[type="password"] { padding: 0; }

				&:focus { outline: none; }
			}
			!*input:-webkit-autofill,
			input:-webkit-autofill:hover, 
			input:-webkit-autofill:focus, 
			input:-webkit-autofill:active  {
				-webkit-text-fill-color: var(--ion-color-white) !important;
				-webkit-box-shadow: 0 0 0 30px transparent inset !important;
				transition: background-color 5000s ease-in-out 0s;
			}*!
			ion-select {
				width: 100%;
				max-width: 100%;
				padding: 0 0 0 0.5rem;
				font-size: 15px;
				letter-spacing: -0.7px;
				color: var(--ion-color-white);
			}
			&.error {
				input,
				ion-input,
				ion-select,
				input[name="code"],
				ion-input[name="code"],
				input[name="password"],
				ion-input[name="password"] {
					color: var(--ion-color-danger);
				}
				& > .error-label,
				& + .error-label {
					display: block;
				}
				ion-select:after {
					background-image: url(../assets/imgs/arrow-down-small-red.svg);
				}
			}
		}

		&__country {
			width: 100%;
			display: flex;
			align-items: center;

			ion-icon {
				width: 22px; height: 22px;
				min-width: 22px; max-width: 22px;
				margin-right: 9px;
				border-radius: 50%;
			}
		}

		&__phone {
			width: 100%;
			display: flex;
			align-items: center;

			input,
			ion-input {
				&[name="code"] {
					width: auto;
					min-width: 36px; max-width: 36px;
					border-right: 1px solid rgba(255, 255, 255, 0.2);
				}
				&[name="phone"] { --padding-start: 14px; }
			}
		}

		&__separate {
			padding: 8px;
		}

		&__info {
			margin: 0 -16px 25px;
			color: rgba(255, 255, 255, 0.5);
			font-size: 14px;
			line-height: 16px;

			p {
				margin: 10px 0 0;
				font-weight: 600;
				color: var(--ion-color-white);
			}

			@media(min-width: 1280px) {
				margin: 0 -16px 47px;
				p { margin: 12px 0 0; }
			}
		}
	}

	&-buttons {
		margin-top: 24px;
		text-align: center;
		@media(min-width: 1200px) {
			// margin-top: 35px;
		}
		@media(min-width: 1440px) {
			// margin-top: 50px;
		}
		ion-button,
		.bcc-button {
			height: 32px;
			--height: 32px;
			margin: 0;
			font-size: 13px;

			--padding-end: 0;
			--padding-start: 0;

			@media(min-width: 1200px) {
				height: 44px;
				--height: 44px;
				font-size: 15px;
			}

			&[disabled] {
				opacity: 0.3;
			}

			ion-icon {
				left: auto;
				right: 8px;
				margin-right: 0;

				@media(min-width: 1200px) {
				}
			}

			&[size="small"] {
				width: 156px;
				height: 24px;
				--height: 24px;
				font-size: 11px;
				font-weight: 400;

				@media(min-width: 1200px) {
					height: 28px;
					--height: 28px;
					font-size: 12px;
				}
			}

			&[color="white-transparent"] {
				background: rgba(255, 255, 255, 0.15);
				border-radius: 15px;
			}

			&[color="white"] {
				--color: var(--ion-color-white);
				--border-color: var(--ion-color-white);
			}

			&[fill="outline"] {
				--border-width: 2px;
			}

			& + ion-button,
			& + .bcc-button {
				margin-top: 24px;
				&[size="small"] { margin-top: 24px; }

				@media(min-width: 1200px) {
					// margin-top: 41px;
					&[size="small"] { margin-top: 32px; }
				}
				@media(min-width: 1440px) {
					margin-top: 41px;
					&[size="small"] { margin-top: 52px; }
				}
			}
		}
	}
}
input:-moz-placeholder { color: rgba(255, 255, 255, 0.2); }
input::-moz-placeholder { color: rgba(255, 255, 255, 0.2); }
input:-ms-input-placeholder { color: rgba(255, 255, 255, 0.2); }
input::-webkit-input-placeholder { color: rgba(255, 255, 255, 0.2); }
*/
.manualErrors ion-item.ion-invalid.ion-pristine,
.manualErrors .item.ion-invalid.ion-pristine {
  --background: transparent;
  --highlight-background: transparent;
  --full-highlight-height: 0;
}
.manualErrors ion-item.ion-invalid.ion-dirty,
.manualErrors .item.ion-invalid.ion-dirty {
  --full-highlight-height: 2px;
  --highlight-background: var(--ion-color-danger);
}
.manualErrors.displayErrors ion-item.ion-invalid,
.manualErrors.displayErrors .item.ion-invalid {
  --full-highlight-height: 2px;
  --highlight-background: var(--ion-color-danger);
}
.manualErrors.displayErrors ion-item.ion-invalid.field-group, .manualErrors.displayErrors ion-item.ion-invalid.transfer-amount,
.manualErrors.displayErrors .item.ion-invalid.field-group,
.manualErrors.displayErrors .item.ion-invalid.transfer-amount {
  --full-highlight-height: 0;
  --highlight-background: transparent;
  border-color: var(--ion-color-danger);
}
.manualErrors.displayErrors .page-list ion-item.error, .manualErrors.displayErrors .page-list ion-item.ion-invalid,
.manualErrors.displayErrors .page-list .item.error,
.manualErrors.displayErrors .page-list .item.ion-invalid {
  z-index: 2;
  --highlight-height: 2px;
  --highlight-background: transparent;
  --border-color: var(--ion-color-danger);
}

ion-item-divider.error-divider {
  border-bottom: unset;
}

ion-item.control-invalid,
.item.control-invalid {
  --highlight-height: 2px;
  --highlight-background: transparent;
  --border-color: var(--ion-color-danger);
  border-bottom: 1px solid red;
}

ion-item.control-comment-invalid,
.item.control-comment-invalid {
  --highlight-height: 2px;
  --highlight-background: transparent;
  --border-color: var(--ion-color-danger);
}

.error-item {
  font-size: 12px;
}

.error-block {
  padding-top: 5px;
  min-height: 20px;
  font-size: 12px;
  text-align: right;
}