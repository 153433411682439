.CardAlert {
	padding: 24px 55px;
	font-size: 0.8125rem;
	font-weight: 400;
	letter-spacing: -0.1px;
	display: flex;
    flex-direction: column;
    justify-content: center;

	@media (max-width:961px)  {
		padding: 24px;
	}

    p { margin: 0; }

	&__icon {

		ion-icon {
			width: 100px;
			height: 100px;
			border: 1px solid var(--ion-color-border);
			background-size: 56px auto;
			border-radius: 50%;
			vertical-align: middle;

			.CardDetail & { background-size: 32px auto; }
		}

		& + .CardAlert__title { margin-top: 24px; }
	}
	&__title {
		font-weight: 600;
		font-size: 16px;
		& + .CardAlert__icon { margin-top: 24px; }
	}
	&__card {
		margin: 24px auto 0;
		&-number { margin-bottom: 8px; }
	}
	.card {
		width: 16.5rem;
		height: 8.125rem;
		padding: 19.5px 1.0625rem 10px;
		border-radius: 15px;
		border: solid 1px #dee4e8;

		&-value {
			font-family: 'OCRAEXT';
			font-size: 19px;
			font-weight: 600;
			display: block;
			text-wrap: nowrap;
		}

		ion-label {
			margin: 0 0 2px;
			font-family: 'SFProText';
			font-weight: 600;
			font-size: 0.5rem;
			color: var(--ion-color-dark);
			text-transform: uppercase;
		}
		ion-grid {
			ion-col {
				&:first-child {
					padding-right: 12px;
				}
				&:last-child {
					padding-left: 20px;
				}
			}
		}
	}

	&__text { margin-top: 24px; }
	&__buttons {
		padding-top: 24px;

		ion-button {
			width: 174px;
			height: 32px;
			--height: 32px;
			display: inline-block;
			& + ion-button { margin-left: 26px; }
		}
	}

	&__field {
		margin-top: 24px;
		text-align: center;

		input,
		ion-input {
			width: 570px;
			max-width: 100%;
			height: 38px;
			display: inline-block;
			font-size: 1.0625rem;
			line-height: 38px;
			border: none;
			outline: none;
			border-radius: 0.3125rem;
			background-color: var(--ion-color-light);
			--placeholder-color: var(--ion-color-gray);
		}
	}


	.CardBlock &,
	.CardUnblock & {
		padding-top: 24px;
		.CardAlert__text { width: 17rem; margin: 16px auto 0; padding-left: 6px; }
	}
	.CardUnblock & {
		padding: 24px 50px;
		.CardAlert__icon + .CardAlert__title { margin-top: 25px; }
		.CardAlert__buttons { padding-top: 23px; padding-left: 7px; }
	}
	.CardDetail & {
		padding: 24px 55px;
		.CardAlert__text { margin-top: 24px; }
		.CardAlert__buttons { padding-top: 24px; }
	}
	.CardDayLimits &,
	.CardMonthLimits & {
		&__field {
			ion-input { --padding-end: 32px; }
		}
	}
	.CardDayLimits &,
	.CardMonthLimits &,
	.CardLimitsConfirmation & {
		padding-top: 28px;
		.CardAlert__title { padding-right: 4px; }
		.CardAlert__text { margin-top: 16px; padding-right: 4px; }
		.CardAlert__buttons { padding-top: 24px; }
	}
	.CardLimitDisable & { 
		padding: 25px 50px 23px;
		.CardAlert__title { padding-right: 4px; }
		.CardAlert__text { padding-left: 4px; }
		.CardAlert__buttons { padding-left: 4px; }
	}

	@media (min-width: 1280px) {
		padding: 2.125rem 40px 2rem;

		&__title {
			padding-right: 5px;
			& + .CardAlert__icon { margin-top: 25px; }
		}
		&__icon {
			& + .CardAlert__title { margin-top: 24px; }
		}
		&__card {
			margin: 2rem auto 0;
			&-number { margin-bottom: 7px; }
		}
		.card {
			padding: 1.9375rem 1.0625rem 1.875rem;
			&-value {
				font-size: 1.25rem;
			}
		}
		&__text {
			margin-top: 13px;
			padding-right: 5px;
		}
		&__buttons {
			padding-top: 28px;
			padding-right: 5px;
			ion-button {
				//width: 272px;
				width: auto;
				height: 38px;
			}
		}
		&__field {
			margin-top: 36px;
			ion-input { height: 2.625rem; line-height: 2.625rem; }
		}

		.CardBlock &,
		.CardUnblock & {
			padding: 43px 40px 32px;
			.CardAlert__title,
			.CardAlert__buttons { padding-right: 0; }
			.CardAlert__text { width: 17rem; margin: 12px auto 0; padding: 0; }
		}
		.CardUnblock & {
			.CardAlert__buttons { padding-top: 32px; padding-left: 0; }
		}
		.CardDetail & {
			padding: 43px 50px 32px;
			.CardAlert__text { margin-top: 13px; padding: 0 10px 0 5px; }
			.CardAlert__buttons { padding-top: 32px; padding-right: 5px; ion-button { width: 337px; } }
		}
		.CardLimitDisable & { 
			padding: 43px 40px 32px;
			.CardAlert__text,
			.CardAlert__title,
			.CardAlert__buttons { padding-right: 0; padding-left: 0; }
			.CardAlert__buttons { padding-top: 33px; }
		}
		.CardDayLimits &,
		.CardMonthLimits & {
			&__field { margin-top: 36px; ion-input { --padding-end: 10px; } }
			.CardAlert__text { margin-top: 12px; }
		}
		.CardDayLimits &,
		.CardMonthLimits &,
		.CardLimitsConfirmation & {
			padding-top: 44px;
			.CardAlert__title { padding-right: 0; }
			.CardAlert__buttons { padding-top: 40px; padding-right: 0; }
		}
		.CardDayLimits & {
			.CardAlert__title { padding-right: 0; }
			.CardAlert__text { padding-right: 1px; }
		}
		.CardLimitsConfirmation & {
			padding-left: 40px;
			padding-right: 40px;
			.CardAlert__text { margin-top: 12px; padding-right: 6px; }
			.CardAlert__field { padding: 0; ion-input { --padding-start: 3px; } }
		}
	}
}
