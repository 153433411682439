// .wrapper {
//   height: 48px;
//   line-height: 45px;
//   margin-bottom: 16px;
// }

// label {
//   display: block;
// }

// .select-group {
//   margin-bottom: -3px;
//   padding: 4px 0 0px 0;
//   text-align: left;
// }

// .select-group-chip {
//   padding: 0 8px 0 14px;
// }

// .select-item {
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   cursor: pointer;
//   height: 32px;
//   line-height: 32px;
//   padding-left: 46px;
//   padding-right: 18px;
//   transition: all .15s;
//   &:hover, &:focus, &:active {
//     background-color: rgba($color: #77a45c, $alpha: .25);
//   }
// }

// .currency {
//   color: var(--ion-color-gray);
// }

.client-select-small,
.client-select--filter {
  // .wrapper {
  //   height: 28px;
  //   line-height: 28px;
  //   margin-bottom: 20px;
  // }
  // .select-custom { 
  //   border-radius: 14px!important;
  //   &:after { right: 7px!important; }
  //   .flex-row { padding: 0 7px 0 0!important; }
  //   .dropdown-list {
  //     border: 1px solid var(--ion-color-border)!important;
  //     border-top: none!important;
  //     border-radius: 0 0 14px 14px!important;
  //     box-shadow: none!important;
  //     background-color: #fff;
  //   }
  //   .placeholder-wrap { display: none!important; }
  //   .label-wrap {
  //     width: 100%;
  //     max-width: 100%!important;
  //     text-align: center;
  //     label { 
  //       width: 100%;
  //       line-height: 26px;
  //       display: block!important;
  //       span { letter-spacing: -0.6px; display: inline-block; }
  //     }
  //   }
  //   &.isOpen {
  //     border-radius: 14px 14px 0 0!important;
  //     .label-wrap {
  //       padding-top: 3px;
  //     }
  //   }
  //   .select-group { padding: 7px 20px 20px; }
  //   .select-item {
  //     max-width: 180px;
  //     padding-left: 44px;
  //     padding-right: 0;
  //     font-size: 12px;
  //     letter-spacing: -0.1px;
  //     color: var(--ion-color-black);
  //     height: 28px;
  //     line-height: 28px;
  //     margin-top: 9px;
  //     overflow: hidden;
  //     & + .select-item { margin-top: 0; }
  //   }
  // }
  .isSelected {
    .select-custom {
      // .flex-row { justify-content: flex-start!important; }
      .label-wrap {
        // width: auto;
        // max-width: none!important;
        ion-chip {
          width: 100%;
          background-color: transparent;
          // ion-label { display: none; }
        }
      }
      // .placeholder-wrap { 
      //   padding-left: 13px;
      //   max-width: 158px;
      //   overflow: hidden;
      //   white-space: nowrap;
      //   display: block!important;
      //   font-size: 12px;
      //   opacity: 1!important;
      // }
    }
  }
}
// .client-select--filter {
//   .wrapper { margin: 7px 0 10px; line-height: 26px; }
// }
// .PrintStatements {
//   .wrapper { margin: 0 0 10px; }
// }
// .client-select-large {
//   .wrapper { height: 56px; line-height: 56px; }
//   .select-custom {
//     &:after { right: 8px; }
//     .flex-row { padding: 0 35px 0 15px; }
//   }
// }
