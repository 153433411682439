ion-select {
  position: relative;
  //--icon-color: transparent;
  //--padding-end: 0.375rem;

  // &:after {
  //   content: '';
  //   position: absolute;
  //   top: 1px; bottom: 1px; right: 0;
  //   width: 28px; 
  //   background-color: var(--ion-color-white);
  //   background-image: url(../assets/imgs/arrow-down-small.svg);
  //   background-repeat: no-repeat;
  //   background-position: center;
  //   background-size: 28px auto;
  //   transition: all 0.25s;
  // }

  // &:hover:after {
  //   opacity: .5;
  // }

}


ion-popover {
  ion-backdrop {
    opacity: 0!important;
    --ion-backdrop-color: transparent;
  }

  &.popover--money-account-property {
    --box-shadow: none;
    --width: auto;
    --height: auto;
    --max-height: none;

    .popover-content {
      margin-top: -5px;
      margin-left: -154px;
      border: 1px solid var(--ion-color-gray);
      border-radius: 12px;
      @media (min-width: 1280px) {
        margin-top: 1.625rem;
        margin-left: 0;
      }
    }
  }

  &.popover-history-filter {
    .popover-wrapper {
      .popover-content {
        width: 282px;
        border-radius: 0;
        box-shadow: none;
        border: none;
        background: transparent;
        overflow: visible;
        // &:after,
        // &:before {
        //   content: '';
        //   position: absolute;
        //   top: -18px;
        //   z-index: 1;
        //   border: 10px solid transparent;
        //   border-bottom: 10px solid var(--ion-color-white);
        // }
        // &:before {
        //   top: -20px;
        //   border: 11px solid transparent;
        //   border-bottom: 10px solid var(--ion-color-border);
        // }
      }
      .popover-viewport {
        width: 100%;
        height: 100%;
        padding: 11px 0;
        border-radius: 15px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.12);
        border: 1px solid var(--ion-color-border);
        background-color: var(--ion-color-white);
        overflow: hidden;
        @media(min-width: 1280px) {
          padding: 11px 7px;
        }
      }
    }
    /*&.popover-start {
      .popover-wrapper {
        .popover-content {
          margin-left: -18px;
          &:after, &:before { left: 50%; }
          &:after { margin-left: -10px; }
          &:before { margin-left: -11px; }
        }
      }
    }
    &.popover-end {
      .popover-wrapper {
        .popover-content {
          margin-left: 12px;
          &:after, &:before { right: 11px; }
          &:after { right: 12px; }
          &:before {  }
        }
      }
    }*/
  }

  &:not(.popover--money-account-property):not(.popover-history-filter):not(.Registration-select) {
    .popover-wrapper {
      .popover-content {
        width: 199px;
        margin-top: 0;
        margin-left: 20px;
        box-shadow: none;
        border-radius: 0 0 0.3125rem 0.3125rem;
        border: 0.5px solid var(--ion-color-border);
        border-top: 1px solid var(--ion-color-success);
        background-color: var(--ion-color-white);

        ion-list,
        .list-md,
        .list-ios {
          margin: 0;
          padding: 0;

          .item {
            --min-height: 36px;
            --border-width: 0;
            --border-color: transparent; 

            &:not(:last-child) {
              border-bottom: 1px solid var(--ion-color-border);
            }

            ion-label {
              margin: 0;
              padding-right: 1.5rem;
              width: 100%;
              font-size: 0.8125rem;
              letter-spacing: -0.08px;
              text-align: right;
            }
            ion-radio {
              width: 100%;
              height: 100%;
              left: 0;
              margin: 0;
              opacity: 0;
              position: absolute;
            }
            &:hover,
            &.item-radio-checked {
              background-color: #eef4eb;
            }
          }
        }

        @media (min-width: 1280px) {
          width: 13.875rem;
          margin-top: 0;
          margin-left: 16px;
          ion-list,
          .list-md,
          .list-ios { 
            .item {
              --min-height: 2.625rem; 
            }
          }
        }
      }
    }
    &.SelectCurrency,
    &.SelectCurrencyMax {
      .popover-wrapper {
        .popover-content {
          width: 56px;
          margin-top: 0;
          margin-top: -27px;
          margin-left: -7px;
          border-radius: 0.125rem;
          border-top: 0.5px solid var(--ion-color-border);

          // &:after {
          //   content: '';
          //   position: absolute;
          //   top: 0; right: 8px;
          //   width: 0.5625rem; height: 2.125rem;
          //   background-image: url(../assets/imgs/arrow-down-small.svg);
          //   background-repeat: no-repeat;
          //   background-position: center;
          //   background-size: 28px auto;
          //   transition: all 0.25s;
          // }

          ion-list,
          .list-md,
          .list-ios {
            .item {
              --min-height: 2.1875rem;
              --padding-start: 0.4375rem;

              ion-label {
                padding: 0;
                text-align: left;
                font-size: 0.6875rem;
                font-weight: 600;
              }
            }
          }
        }
      }
    }
    &.SelectCurrencyMax {
      .popover-wrapper {
        .popover-content {
          width: 170px;
          margin-top: -8px;
          margin-left: -5px;
          &:after { right: 12px; background-image: url(/assets/imgs/arrow-up-small.svg); }
          ion-list,
          .list-md,
          .list-ios {
            .item {
              --inner-padding-end: 29px;

              ion-label {
                color: var(--ion-color-success);
                text-align: right;
              }
            }
          }
        }
      }
    }
    &.AccountCurrency,
    &.HistoryFilterTotal {
      .popover-wrapper {
        .popover-content {
          width: 230px;
          margin-top: 0;
          border-radius: 0.875rem;
          border-top: 0.5px solid var(--ion-color-border);

          margin: 0 0px 0 -4px;

          &:after {
            content: '';
            position: absolute;
            top: 1px; right: 15px;
            width: 0.5625rem; height: 28px;
            background-image: url(/assets/imgs/arrow-up-small.svg);
            background-repeat: no-repeat;
            background-position: center;
            background-size: 28px auto;
            transition: all 0.25s;
          }

          ion-list,
          .list-md,
          .list-ios {

            .item {
              --min-height: 1.75rem;
              border: none!important;
              padding-left: 3px;

              ion-label {
                padding: 0;
                font-size: 0.75rem;
                letter-spacing: 0;
                text-align: center;
              }
            }
          }

          @media (min-width: 1280px) {
            width: 265px;
            margin: -1px 0 0 -1px;
          }
          @media (min-width: 1440px) {
            width: 317px;
          }
        }
      }
    }
    &.SelectCountry {
      .popover-wrapper {
        .popover-content {
          width: 230px;
          margin-left: 0;
          margin-top: 14px;
          ion-list,
          .list-md,
          .list-ios {
            .item {
              ion-label { padding: 0; }
            }
          }
          @media (min-width: 1280px) {
            width: 230px;
            margin-left: 0;
            margin-top: 14px;
            ion-list,
            .list-md,
            .list-ios {
              .item {
                ion-label { padding: 0; }
              }
            }
          }
        }
      }
    }
    &.SelectTemplateDescription {
      .popover-wrapper {
        .popover-content {
          width: 261px;
          margin: 2px 0 0 4px;
        }
      }
    }
  }

  &.Registration-select {
    .popover-wrapper {
      .popover-content {
        width: 582px;
        padding: 27px 25px;
        border-radius: 20px;
        background-color: var(--ion-color-white);

        ion-list,
        .list-md,
        .list-ios {
          margin: 0;
          padding: 0;

          .item {
            --min-height: auto;
            --border-width: 0;
            --border-color: transparent; 
            --background: transparent;
            margin-bottom: 25px;
            color: var(--ion-color-black);

            ion-label {
              margin: 0;
              padding: 0;
              width: 100%;
              font-size: 15px;
              letter-spacing: 1.07px;
            }
            ion-radio {
              width: 100%;
              height: 100%;
              left: 0;
              margin: 0;
              opacity: 0;
              position: absolute;
            }
            &:hover,
            &.item-radio-checked {
              color: var(--ion-color-success);
            }
          }
        }
      }
    }
  }
}
