small { font-size: 90%; }
.History-filter__value small { font-size: 11px; }

a {
  transition: all 0.25s;
  text-decoration: underline;
  color: var(--ion-color-success);

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    color: var(--ion-color-success);
  }
}

.clearfix:after {
	content: '';
	display: table;
	clear: both;
}

.linkified {
  color: var(--ion-color-success-contrast);
  &:hover,
  &:focus,
  &:active {
    text-decoration: underline;
    color: var(--ion-color-success-contrast);
  }
}