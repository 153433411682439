// http://ionicframework.com/docs/theming/
//@import "~@ionic/angular/css/core.css";
//@import "~@ionic/angular/css/normalize.css";
//@import "~@ionic/angular/css/structure.css";
//@import "~@ionic/angular/css/typography.css";
//
//@import "~@ionic/angular/css/padding.css";
//@import "~@ionic/angular/css/float-elements.css";
//@import "~@ionic/angular/css/text-alignment.css";
//@import "~@ionic/angular/css/text-transformation.css";
//@import "~@ionic/angular/css/flex-utils.css";

$colors: ( primary : #488aff, secondary : #32db64, danger : #f53d3d, light : #f4f4f4, dark : #222, cal-color : #ff8732);
@import "./../node_modules/ion4-calendar/dist/index.scss";
@import "./../node_modules/angular-notifier/styles";

:root {
  //--app-color-red: #eb0000;
  --account-color-red: #A34D63;
  //--account-color-orange: #ff9800;
  --account-color-orange: #B96950;
  //--account-color-yellow: #ffc300;
  --account-color-yellow: #E3C87C;
  //--account-color-green: #70c65b;
  --account-color-green: #B3C784;
  //--account-color-blue: #3283cb;
  --account-color-blue: #749BD8;
  //--account-color-purple: #9013fe;
  --account-color-purple: #704396;
  //--account-color-teal: #32cbc5;
  --account-color-teal: #799F8D;

  --account-color-lime: #DBE08E;
  --account-color-silver: #808080;
  --account-color-turquoise: #94C4CD;
  --account-color-black: #1B1B1B;
  --account-color-navy: #47559C;

  // TODO move to crypto service
  --crypto-color-green: #77a45c;
  --crypto-color-red: #eb0000;
  --crypto-color-dark-gray: #596778;
  --crypto-color-light-gray: #b6bcc4;
  --crypto-color-header-background: #f9fbfb;
}

