@font-face {
  font-family: 'SFProText';
  src: url('../assets/fonts/SFProText-Light.woff') format('woff'),
    url('../assets/fonts/SFProText-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'SFProText';
  src: url('../assets/fonts/SFProText-Regular.woff') format('woff'),
    url('../assets/fonts/SFProText-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'SFProText';
  src: url('../assets/fonts/SFProText-Medium.woff') format('woff'),
    url('../assets/fonts/SFProText-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'SFProText';
  src: url('../assets/fonts/SFProText-Semibold.woff') format('woff'),
    url('../assets/fonts/SFProText-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'SFProText';
  src: url('../assets/fonts/SFProText-Bold.woff') format('woff'),
    url('../assets/fonts/SFProText-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'SFProText';
  src: url('../assets/fonts/SFProText-Heavy.woff') format('woff'),
    url('../assets/fonts/SFProText-Heavy.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'SFProDisplay';
  src: url('../assets/fonts/SFProDisplayRegular.woff') format('woff'),
    url('../assets/fonts/SFProDisplayRegular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'SFProDisplay';
  src: url('../assets/fonts/SFProDisplaySemibold.woff') format('woff'),
    url('../assets/fonts/SFProDisplaySemibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'SFProDisplay';
  src: url('../assets/fonts/SFProDisplay-Bold.woff') format('woff'),
    url('../assets/fonts/SFProDisplay-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Robotolight.woff') format('woff'),
    url('../assets/fonts/Robotolight.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Robotoregular.woff') format('woff'),
    url('../assets/fonts/Robotoregular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Robotoitalic.woff') format('woff'),
    url('../assets/fonts/Robotoitalic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Robotomedium.woff') format('woff'),
    url('../assets/fonts/Robotomedium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Robotobold.woff') format('woff'),
    url('../assets/fonts/Robotobold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'OCRAEXT';
  src: url('../assets/fonts/OCRAEXT.woff') format('woff'),
    url('../assets/fonts/OCRAEXT.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Exo';
  src: url('../assets/fonts/Exo-Regular.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Exo';
  src: url('../assets/fonts/Exo-Medium.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Exo';
  src: url('../assets/fonts/Exo-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Exo';
  src: url('../assets/fonts/Exo-Bold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Exo';
  src: url('../assets/fonts/Exo-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'PTSans';
  src: url('../assets/fonts/ptsans.woff') format('woff'),
    url('../assets/fonts/ptsans.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'PTSans';
  src: url('../assets/fonts/ptsansbold.woff') format('woff'),
    url('../assets/fonts/ptsansbold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}