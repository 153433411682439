ion-button {
  position: relative;
  margin: 0;
  --margin-top: 0;
  --margin-bottom: 0;
  --margin-end: 0;
  --margin-start: 0;

  --padding-start: 2.75rem;
  --padding-end: 2.75rem;

  font-weight: 600;
  letter-spacing: -0.1px;
  text-transform: none;

  ion-icon {
  	position: absolute;
  	top: 50%;
  	left: -2rem;
  	margin-top: -0.875rem;
  }

  &[size="large"] {
    height: 52px;
  }
}

ion-toggle {
  flex-shrink: 0;
  width: 36px;
  height: 14px;

  position: relative;
  padding: 4px 0;
  margin-left: 12px;

  --background: transparent;
  --background-checked: transparent;
  --handle-background: transparent;
  --handle-background-checked: transparent;

  background-color: var(--ion-color-white);
  border-radius: 14px;
  border: 1px solid rgba(182,188,196,0.25);

  @media (min-width: 1280px) {
    width: 44px;
    padding-top: 6px;
    padding-bottom: 6px;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0; left: 0;
    z-index: 10;
    width: 100%; height: 100%;
    transition: background-color .16s;
    background-color: var(--ion-color-white);
    border-radius: 14px;
  }

  &:after {
    content: '';
    position: absolute;
    top: 1px; left: 1px;
    z-index: 11;
    width: 20px; height: 20px;
    border-radius: 50%;
    background-color: var(--ion-color-white);
    box-shadow: 0 3px 1px 0 rgba(0, 0, 0, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0.16), 0 3px 8px 0 rgba(0, 0, 0, 0.15);

    -webkit-transition-duration: .3s;
    transition-duration: .3s;
    -webkit-transition-property: background-color, -webkit-transform, transform;
    transition-property: background-color, -webkit-transform, transform;
    will-change: transform, background-color;

    @media (min-width: 1280px) {
      width: 24px; height: 24px;
    }
  }

  &.toggle-checked {
    border-color: var(--ion-color-success);
    background-color: var(--ion-color-success);

    &:before {
      background-color: var(--ion-color-success);
    }

    &:after {
      -webkit-transform: translate3d(14px,0,0);
      transform: translate3d(14px,0,0);

      @media (min-width: 1280px) {
        -webkit-transform: translate3d(18px,0,0);
        transform: translate3d(18px,0,0);
      }
    }
  }
}
//:host(.toggle-checked) .toggle-inner {
//  box-shadow: none;
//}
//:host(.ion-color.toggle-checked) .toggle-inner {
//  background: var(--ion-color-white);
//}

ion-textarea {
  --placeholder-color: var(--ion-color-medium);
  --placeholder-font-style: normal;
  --placeholder-font-weight: 400;
  --placeholder-opacity: 1;
  font-size: 0.8125rem;
  line-height: 1rem;
  letter-spacing: -0.2px;
}

textarea {
  height: 100%;
  overflow-y:scroll;
}

ion-datetime {
  padding: 0;
  //background-image: url(/assets/imgs/calendar.svg);
  background-repeat: no-repeat;
  background-position: right center;

  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 22px;
  letter-spacing: normal;
  --placeholder-color: var(--ion-color-medium);
}
