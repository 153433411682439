// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {

  /** primary **/
  --ion-color-primary: #3283cb;
  --ion-color-primary-rgb: 50,131,203;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #2c73b3;
  --ion-color-primary-tint: #478fd0;

  /** secondary **/
  --ion-color-secondary: #32cbc5;
  --ion-color-secondary-rgb: 50,203,197;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0,0,0;
  --ion-color-secondary-shade: #2cb3ad;
  --ion-color-secondary-tint: #47d0cb;

  /** tertiary **/
  --ion-color-tertiary: #9013fe;
  --ion-color-tertiary-rgb: 144,19,254;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #7f11e0;
  --ion-color-tertiary-tint: #9b2bfe;

  /** success **/
  --ion-color-success: #77a45c;
  --ion-color-success-rgb: 119,164,92;
  --ion-color-success-contrast: #ffffff; // #000000;
  --ion-color-success-contrast-rgb: 0,0,0;
  --ion-color-success-shade: #699051;
  --ion-color-success-tint: #85ad6c;

  /** warning **/
  --ion-color-warning: #ecb200;
  --ion-color-warning-rgb: 236,178,0;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #d09d00;
  --ion-color-warning-tint: #eeba1a;

  /** danger **/
  --ion-color-danger: #eb0000;
  --ion-color-danger-rgb: 235,0,0;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #cf0000;
  --ion-color-danger-tint: #ed1a1a;

  /** dark **/
  --ion-color-dark: #596778;
  --ion-color-dark-rgb: 89,103,120;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #4e5b6a;
  --ion-color-dark-tint: #6a7686;

  /** medium **/
  --ion-color-medium: #a1aab6;
  --ion-color-medium-rgb: 161,170,182;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0,0,0;
  --ion-color-medium-shade: #8e96a0;
  --ion-color-medium-tint: #aab3bd;
  --ion-color-medium-op01: rgba(161, 170, 182, 0.1);

  /** light **/
  --ion-color-light: #f3f6f7;
  --ion-color-light-rgb: 243,246,247;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #d6d8d9;
  --ion-color-light-tint: #f4f7f8;

  /** gray **/
  --ion-color-gray: #b6bcc4;
  --ion-color-gray-rgb: 182,188,196;
  --ion-color-gray-contrast: #000000;
  --ion-color-gray-contrast-rgb: 0,0,0;
  --ion-color-gray-shade: #a0a5ac;
  --ion-color-gray-tint: #bdc3ca;

  /* font */
  --ion-font-family: 'SFProText';
  --ion-default-font: 'SFProText';
  --ion-font-secondary: 'Exo', sans-serif;


  /* color */
  --ion-color-black: #161616;
  --ion-color-white: #fff;
  --ion-color-gray-light: #e2e4e7;
  --ion-color-yellow: #ecb200;

  --gekard-main-gradient: linear-gradient(#2d3367, #44627e);

  --ion-color-border: var(--ion-color-gray-light);
  --ion-placeholder-text-color: var(--ion-color-medium);
  --bullet-background-active: var(--ion-color-dark);


  --ripple-color: var(--ion-color-light);


  /* padding */
  --ion-padding: 24px;
  @media (min-width: 1280px) {
    --ion-padding: 2rem;
  }

  --ion-grid-padding: 0;

  --ion-grid-padding-xs: 0;
  --ion-grid-padding-sm: 0;
  --ion-grid-padding-md: 0;
  --ion-grid-padding-lg: 0;
  --ion-grid-padding-xl: 0;
}

html {
  --ion-default-font: 'SFProText';
}

ion-item {
  --min-height: 3rem;
  --border-color: var(--ion-color-border);
}
ion-input,
ion-select,
.sc-ion-input-md-h,
.sc-ion-input-ios-h {
  margin: 0;
  // --placeholder-color: var(--ion-color-medium);
  // --placeholder-opacity: 1;

  --placeholder-color: var(--ion-color-black);
  --placeholder-opacity: .33;
}
ion-select {
  --icon-color: var(--ion-color-black);
}
ion-input {
  --padding-top: 0;
  --padding-bottom: 0;
}

ion-backdrop {
  opacity: 0.82!important;
  --ion-backdrop-color: rgb(17, 26, 37);
}

ion-button {
  --border-width: 1px;
  --border-radius: 26px;
}

$colors: ( primary : #488aff, secondary : #32db64, danger : #f53d3d, light : #f4f4f4, dark : #222, cal-color : #ff8732);

.ion-color-gray {
  --ion-color-base: var(--ion-color-gray);
  --ion-color-base-rgb: var(--ion-color-gray-rgb);
  --ion-color-contrast: var(--ion-color-gray-contrast);
  --ion-color-contrast-rgb: var(--ion-color-gray-contrast-rgb);
  --ion-color-shade: var(--ion-color-gray-shade);
  --ion-color-tint: var(--ion-color-gray-tint);
}
