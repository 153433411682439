.top-up {
  height: calc(100% - 60px);
  padding: 32px 24px 0;

  background-color: #FFFFFF;

  &__warning-banner {
    padding: 14px 16px;

    gap: 16px;

    border-radius: 4px;
    background-color: #FAEBEB;

    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    color: #F20D0D;
  }

  &__main, &-list, &__footer, &__footer-button, &__warning-banner, &__footer-copy-message {
    display: flex;
  }

  &__main, &-list {
    flex-direction: column;
  }

  &__main {
    gap: 24px;
  }

  &-list {
    &__item {
      padding: 12px 0;

      display: flex;
      justify-content: space-between;

      &:not(:last-of-type) {
        border-bottom: 1px solid var(--ion-color-border);
      }
    }

    &__label, &__value {
      font-size: 15px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: -0.23px;
    }

    &__label {
      color: #8F96A3;
    }

    &__value {
      color: #000;
    }
  }

  &__footer {
    height: 60px;
    position: relative;

    align-items: center;
    justify-content: center;

    border: none;
    background-color: #FFFFFF;
  }

  &__footer-button {
    width: 100%;
    padding: 15px 0;

    align-items: center;
    justify-content: center;
    gap: 4px;

    background: none;
    border-top: 1px solid var(--ion-color-border);

    font-size: 15px;
    font-weight: 500;
    line-height: 20px;

    color: #1B1B1B;

    &_copy {
      color: #77A45C;

      ion-icon {
        color: #77A45C;
      }
    }

    &_back {
      ion-icon {
        color: #1B1B1B;
      }
    }
  }

  &__footer-copy-message {
    position: absolute;
    bottom: 70px;

    justify-content: center;
    align-items: center;

    color: #8F96A3;
  }
}
