.RCComponent {
  padding: 0;
  margin: 0;
  background-color: transparent;

  .title {
    height: 1.375rem;
    overflow: visible;
    position: relative;
    margin-bottom: 0.78125rem;
    padding: 0;
    text-align: center;

    .ExportStatements & {
      margin-bottom: 12px;
      @media (min-width: 1440px) {
        padding-right: 10px;
      }
    }

    .switch-btn {
      width: 10rem; height: 1.375rem;
      min-height: 1.375rem;
      font-size: 0.6875rem;
      line-height: 1.375rem;
      color: var(--ion-color-dark);
      border-radius: 0.9375rem;
      background-color: transparent;
      border: 0.5px solid var(--ion-color-gray);

      float: none;
      display: inline-block;
      vertical-align: top;

      .ExportStatements & {
        min-height: 21px;
        height: 21px;
        line-height: 21px;
      }
    }

    .back,
    .forward {
      position: absolute; top: 0;
      width: auto; height: auto;
      min-height: auto;
      margin: 0;
      --padding-end: 0; --padding-start: 0;

      ion-icon {
        position: static;
        width: 1.75rem; height: 1.25rem;
        margin: 0;
        font-size: 0;
        color: transparent;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
    .back { left: 0; ion-icon { background-image: url(/assets/imgs/arrow-left-small-green.svg); } }
    .forward { right: 0; ion-icon { background-image: url(/assets/imgs/arrow-right-small-green.svg); } }
  }

  &.RCComponent-curmonthactive .switch-btn { background: var(--ion-color-success); color: var(--ion-color-white); border-color: var(--ion-color-success); }

  .week-toolbar {
    --background: transparent;
    --min-height: auto;
  }

  .week-title.transparent {
    height: auto;
    padding: 0;
    margin: 0;
    color: var(--ion-color-dark);
    font-size: 0.75rem;
    line-height: 1.8125rem;
  }

  .primary .days .last-month-day p,
  .primary .days .next-month-day p { color: var(--ion-color-gray); }
  .primary .days .last-month-day.on-selected p,
  .primary .days .next-month-day.on-selected p { color: var(--ion-color-white); }
  .primary .days.between .next-month-day p { color: var(--ion-color-white); }

  .primary .days .today p,
  .primary .days .marked p,
  .primary button.days-btn small { color: var(--ion-color-success); }

  .primary .endSelection button.days-btn p { color: var(--ion-color-white) !important; }

  .primary .endSelection:after,
  .primary .startSelection:after { display: none; }

  .primary .between button.days-btn { background: rgba(119, 164, 92, .5); }

  .primary .days .on-selected,
  .primary .endSelection button.days-btn,
  .primary .startSelection button.days-btn { background: var(--ion-color-success); }

  .primary button { border-radius: 0 !important; }

  .primary div[class="days"] button.on-selected,
  .primary div[class="days is-last-wrap"] button.on-selected,
  .primary div[class="days is-first-wrap"] button.on-selected { border-radius: 1.8125rem !important; }

  .primary .startSelection button.days-btn { border-top-left-radius: 1.8125rem !important; border-bottom-left-radius: 1.8125rem !important; }
  .primary .endSelection button.days-btn { border-top-right-radius: 1.8125rem !important; border-bottom-right-radius: 1.8125rem !important; }
}

.popover-history-filter {
  .RCComponent {
    .title {

      .switch-btn {
        font-weight: 600;
        font-size: 15px;
        color: var(--ion-color-dark);
        border: none;
        background: transparent;
      }

      .back, .forward {
        top: 2px;
      }
    }
  }
}
