.bcc-button {
  position: relative;
  width: 100%;
  max-width: 270px;
  height: 35px;
  line-height: 30px;
  border-radius: 26px;
  color: var(--ion-color-white);
  font-family: SFProText;
  font-size: 13px;
  font-weight: 600;
  text-align: center;

  &_md {
    height: 44px;
  }

  &_md {
    height: 44px;
  }

  &_large {
    width: 316px;
    height: 52px;
    font-size: 15px;
  }

  &:focus {
    outline: none;
  }

  ion-icon {
    position: absolute;
    right: 10px;
  }

  &.bcc-button-green {
    background-color: var(--ion-color-success);
  }

  &.bcc-button-white {
    background-color: var(--ion-color-success);
  }

  &.bcc-button-outline {
    background-color: transparent;

    &.bcc-button-green {
      color: var(--ion-color-success);
      border: 2px solid var(--ion-color-success);

      ion-skeleton-text {
        background-color: var(--ion-color-success);
      }
    }

    &.bcc-button-white {
      color: var(--ion-color-white);
      border: 2px solid var(--ion-color-white);

      ion-skeleton-text {
        background-color: var(--ion-color-white);
      }
    }
  }
}

.bcc-button-small {
  position: relative;
  width: 100%;
  max-width: 270px;
  height: 35px;
  line-height: 24px;
  border-radius: 26px;
  color: var(--ion-color-white);

  font-family: SFProText;
  font-size: 13px;
  font-weight: 600;
  text-align: center;

  &:focus {
    outline: none;
  }

  ion-icon {
    position: absolute;
    right: 6px;
  }

  &.bcc-button-green {
    background-color: var(--ion-color-success);
  }

  &.bcc-button-white {
    background-color: var(--ion-color-success);
  }

  &.bcc-button-outline {
    background-color: transparent;

    &.bcc-button-green {
      color: var(--ion-color-success);
      border: 2px solid var(--ion-color-success);
    }

    &.bcc-button-white {
      color: var(--ion-color-white);
      border: 2px solid var(--ion-color-white);
    }
  }
}
