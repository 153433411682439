ion-icon {
  width: 1.75rem;
  height: 1.75rem;
  color: transparent;

  &[name*="icon-"],
  &[class*="icon-"],
  &[ng-reflect-name*="icon-"] {
    background-size: auto;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    --background-size: auto;
    --background-color: transparent;
    --background-repeat: no-repeat;
    --background-position: center;

    outline: none;
  }
  //&[name*="icon-user"],
  //&[ng-reflect-name*="icon-user"] {
  //  background-image: url(../assets/imgs/user.svg);
  //}
  //&[name*="icon-users"],
  //&[ng-reflect-name*="icon-users"] {
  //  background-image: url(../assets/imgs/users.svg);
  //}
  //&[name*="icon-map"],
  //&[ng-reflect-name*="icon-map"] {
  //  background-image: url(../assets/imgs/map.svg);
  //}
  //&[name*="icon-disclosure"],
  //&[ng-reflect-name*="icon-disclosure"] {
  //  background-image: url(../assets/imgs/disclosure.svg);
  //}
  //&[name*="icon-Approve"],
  //&[ng-reflect-name*="icon-Approve"] {
  //  background-image: url(../assets/imgs/Approve.svg);
  //}
  //&[name*="icon-ScanDoc"],
  //&[ng-reflect-name*="icon-ScanDoc"] {
  //  background-image: url(../assets/imgs/ScanDoc.svg);
  //}
  //&[name*="icon-Selfie"],
  //&[ng-reflect-name*="icon-Selfie"] {
  //  background-image: url(../assets/imgs/Selfie.svg);
  //}
  //&[name*="icon-back"],
  //&[ng-reflect-name*="icon-back"] {
  //  background-image: url(../assets/imgs/back.svg);
  //}
  //&[name*="icon-back-small"],
  //&[ng-reflect-name*="icon-back-small"] {
  //  background-image: url(../assets/imgs/back-small.svg);
  //}
  //&[name*="icon-edit"],
  //&[ng-reflect-name*="icon-edit"] {
  //  background-image: url(../assets/imgs/edit.svg);
  //}
  //&[name*="icon-edit-small"],
  //&[ng-reflect-name*="icon-edit-small"] {
  //  background-image: url(../assets/imgs/edit-small.svg);
  //}
  //&[name*="icon-arrow-down-small"],
  //&[ng-reflect-name*="icon-arrow-down-small"] {
  //  background-image: url(../assets/imgs/arrow-down-small.svg);
  //}
  //&[name*="icon-arrow-down-small-gray"],
  //&[ng-reflect-name*="icon-arrow-down-small-gray"] {
  //  background-image: url(../assets/imgs/arrow-down-small-gray.svg);
  //}
  //&[name*="icon-back-InterestRate"],
  //&[ng-reflect-name*="icon-back-InterestRate"] {
  //  background-image: url(../assets/imgs/back-InterestRate.svg);
  //}
  //&[name*="icon-arrow-up-small"],
  //&[ng-reflect-name*="icon-arrow-up-small"] {
  //  background-image: url(../assets/imgs/arrow-up-small.svg);
  //}
  //&[name*="icon-arrow-right"],
  //&[ng-reflect-name*="icon-arrow-right"] {
  //  background-image: url(../assets/imgs/arrow-right.svg);
  //}
  //&[name*="icon-arrow-right-small"],
  //&[ng-reflect-name*="icon-arrow-right-small"] {
  //  background-image: url(../assets/imgs/arrow-right-small.svg);
  //}
  //&[name*="icon-arrow-right-small-green"],
  //&[ng-reflect-name*="icon-arrow-right-small-green"] {
  //  background-image: url(../assets/imgs/arrow-right-small-green.svg);
  //}
  //&[name*="icon-arrow-left-small-green"],
  //&[ng-reflect-name*="icon-arrow-left-small-green"] {
  //  background-image: url(../assets/imgs/arrow-left-small-green.svg);
  //}
  //&[name*="icon-arrow-right-small-white"],
  //&[ng-reflect-name*="icon-arrow-right-small-white"] {
  //  background-image: url(../assets/imgs/arrow-right-small-white.svg);
  //}
  //&[name*="icon-arrow-right-small-gray"],
  //&[ng-reflect-name*="icon-arrow-right-small-gray"] {
  //  background-image: url(../assets/imgs/icon-arrow-right-small-gray.svg);
  //}
  //&[name*="icon-arrow-green"],
  //&[ng-reflect-name*="icon-arrow-green"] {
  //  background-image: url(../assets/imgs/arrow-green.svg);
  //}
  //&[name*="icon-arrow-white"],
  //&[ng-reflect-name*="icon-arrow-white"] {
  //  background-image: url(../assets/imgs/arrow-white.svg);
  //}
  //&[name*="icon-arrow-top"],
  //&[ng-reflect-name*="icon-arrow-top"] {
  //  background-image: url(../assets/imgs/arrow.svg);
  //}
  &[name*="icon-arrow-top-green"],
  &[ng-reflect-name*="icon-arrow-top-green"] {
    background-image: url(../assets/imgs/arrow-top-green.png);
  }
  //&[name*="icon-arrow-support"],
  //&[ng-reflect-name*="icon-arrow-support"] {
  //  background-image: url(../assets/imgs/arrow-support.svg);
  //  background-size: 100% auto;
  //}
  //&[name*="icon-arrow-support-green"],
  //&[ng-reflect-name*="icon-arrow-support-green"] {
  //  background-image: url(../assets/imgs/arrow-support-green.svg);
  //  background-size: 100% auto;
  //}
  //&[name*="icon-arrow-right-long-green"],
  //&[ng-reflect-name*="icon-arrow-right-long-green"] {
  //  background-image: url(../assets/imgs/arrow-right-long-green.svg);
  //}
  //&[name*="icon-arrow-right-long-white"],
  //&[ng-reflect-name*="icon-arrow-right-long-white"] {
  //  background-image: url(../assets/imgs/arrow-right-long-white.svg);
  //}
  //&[name*="icon-arrow-right-long-dark"],
  //&[ng-reflect-name*="icon-arrow-right-long-dark"] {
  //  background-image: url(../assets/imgs/arrow-right-long-dark.svg);
  //}
  //&[name*="icon-arrow-right-long-gray"],
  //&[ng-reflect-name*="icon-arrow-right-long-gray"] {
  //  background-image: url(../assets/imgs/arrow-right-long-gray.svg);
  //}

  //&[name*="icon-topUpAccount"],
  //&[ng-reflect-name*="icon-topUpAccount"] {
  //  background-image: url(../assets/imgs/topUpAccount.svg);
  //}
  &[name*="icon-topUpBarcode"],
  &[ng-reflect-name*="icon-topUpBarcode"] {
    width: 4rem;
    height: 4rem;
    background-image: url(../assets/imgs/topUpBarcode.svg);
  }
  &[name*="icon-topUpPlace"],
  &[ng-reflect-name*="icon-topUpPlace"] {
    width: 4rem;
    height: 4rem;
    background-image: url(../assets/imgs/topUpPlace.svg);
  }
  //&[name*="icon-transferToСard"],
  //&[ng-reflect-name*="icon-transferToСard"] {
  //  background-image: url(../assets/imgs/transferToСard.svg);
  //  .active & { background-image: url(../assets/imgs/transferToСard-green.svg); }
  //}

  //&[name*="icon-transferToСard-green"],
  //&[ng-reflect-name*="icon-transferToСard-green"] {
  //  background-image: url(../assets/imgs/transferToСard-green.svg);
  //}
  //&[name*="icon-transferToСard-white"],
  //&[ng-reflect-name*="icon-transferToСard-white"] {
  //  background-image: url(../assets/imgs/transfer-to-card-white.svg);
  //}
  //&[name*="icon-cardToCard"],
  //&[ng-reflect-name*="icon-cardToCard"] {
  //  background-image: url(../assets/imgs/cardToCard.svg);
  //  .active & { background-image: url(../assets/imgs/cardToCard-green.svg); }
  //}

  //&[name*="icon-cardToCard-green"],
  //&[ng-reflect-name*="icon-cardToCard-green"] {
  //  background-image: url(../assets/imgs/cardToCard-green.svg);
  //}
  //&[name*="icon-bankTransfer"],
  //&[ng-reflect-name*="icon-bankTransfer"] {
  //  background-image: url(../assets/imgs/bankTransfer.svg);
  //  .active & { background-image: url(../assets/imgs/bankTransfer-green.svg); }
  //}

  //&[name*="icon-bankTransfer-green"],
  //&[ng-reflect-name*="icon-bankTransfer-green"] {
  //  background-image: url(../assets/imgs/bankTransfer-green.svg);
  //}
  //&[name*="icon-transferToContact"],
  //&[ng-reflect-name*="icon-transferToContact"] {
  //  background-image: url(../assets/imgs/transferToContact.svg);
  //  .active & { background-image: url(../assets/imgs/transferToContact-green.svg); }
  //}
  //&[name*="icon-transferToContact-green"],
  //&[ng-reflect-name*="icon-transferToContact-green"] {
  //  background-image: url(../assets/imgs/transferToContact-green.svg);
  //}
  //&[name*="icon-sendMoneyRequest"],
  //&[ng-reflect-name*="icon-sendMoneyRequest"] {
  //  background-image: url(../assets/imgs/sendMoneyRequest.svg)!important;
  //  .active & { background-image: url(../assets/imgs/sendMoneyRequest-green.svg)!important; }
  //}
  //&[name*="icon-sendMoneyRequest-green"],
  //&[ng-reflect-name*="icon-sendMoneyRequest-green"] {
  //  background-image: url(../assets/imgs/sendMoneyRequest-green.svg);
  //}
  //&[name*="icon-currencyExchange"],
  //&[ng-reflect-name*="icon-currencyExchange"] {
  //  background-image: url(../assets/imgs/currencyExchange.svg);
  //  .active & { background-image: url(../assets/imgs/currencyExchange-green.svg); }
  //}
  //&[name*="icon-currencyExchange-green"],
  //&[ng-reflect-name*="icon-currencyExchange-green"] {
  //  background-image: url(../assets/imgs/currencyExchange-green.svg);
  //}
  //&[name*="icon-cryptoWallet"],
  //&[ng-reflect-name*="icon-cryptoWallet"] {
  //  background-image: url(../assets/imgs/cryptoWallet.svg);
  //  .active & { background-image: url(../assets/imgs/cryptoWallet-green.svg); }
  //}
  &[name*="icon-cryptoWallet-green"],
  &[ng-reflect-name*="icon-cryptoWallet-green"] {
    background-image: url(../assets/imgs/cryptoWallet-green.svg);
  }
  //&[name*="icon-support"],
  //&[ng-reflect-name*="icon-support"] {
  //  background-image: url(../assets/imgs/support.svg);
  //  .active & { background-image: url(../assets/imgs/support-green.svg); }
  //}

  &[name*="icon-support-green"],
  &[ng-reflect-name*="icon-support-green"] {
    background-image: url(../assets/imgs/support-green.svg);
  }
  //&[name*="icon-settings"],
  //&[ng-reflect-name*="icon-settings"] {
  //  background-image: url(../assets/imgs/settings.svg);
  //  .active & { background-image: url(../assets/imgs/settings-green.svg); }
  //}
  &[name*="icon-settings-green"],
  &[ng-reflect-name*="icon-settings-green"] {
    background-image: url(../assets/imgs/settings-green.svg);
  }



  &[name*="icon-cat-bottom"],
  &[ng-reflect-name*="icon-cat-bottom"] {
    background-image: url(../assets/imgs/cat-bottom.png);
  }
  &[name*="icon-cat-down"],
  &[ng-reflect-name*="icon-cat-down"] {
    background-image: url(../assets/imgs/cat-down.png);
  }
  &[name*="icon-cat-peeking"],
  &[ng-reflect-name*="icon-cat-peeking"] {
    background-image: url(../assets/imgs/cat-peeking.png);
  }
  &[name*="cat-info-crypto-receive"],
  &[ng-reflect-name*="cat-info-crypto-receive"] {
    width: 52px;
    height: 66px;
    background-size: 100% auto;
    background-image: url(../assets/imgs/cat-info-crypto-receive@2x.png);
  }
  &[name*="cat-info-crypto-confirmation"],
  &[ng-reflect-name*="cat-info-crypto-confirmation"] {
    width: 79px;
    height: 25px;
    background-size: 100% auto;
    background-image: url(../assets/imgs/cat-info-crypto-confirmation@2x.png);
  }

  &[name*="icon-close"],
  &[ng-reflect-name*="icon-close"] {
    background-image: url(../assets/imgs/close.svg);
  }
  //&[name*="icon-close-small"],
  //&[ng-reflect-name*="icon-close-small"] {
  //  background-image: url(../assets/imgs/close-small.svg);
  //}
  &[name*="icon-close-small-red"],
  &[ng-reflect-name*="icon-close-small-red"] {
    background-image: url(../assets/imgs/close-small-red.svg);
  }
  //&[name*="icon-trash"],
  //&[ng-reflect-name*="icon-trash"] {
  //  background-image: url(../assets/imgs/trash.svg);
  //}
  &[name*="icon-arrow-slider"],
  &[ng-reflect-name*="icon-arrow-slider"] {
    background-image: url(../assets/imgs/arrow-slider.svg);
  }
  &[name*="icon-eur"],
  &[ng-reflect-name*="icon-eur"] {
    background-image: url(../assets/imgs/eur.svg);
  }
  &[name*="icon-eur-white"],
  &[ng-reflect-name*="icon-eur-white"] {
    background-image: url(../assets/imgs/eur-white.svg);
  }
  &[name*="icon-eur-png"],
  &[ng-reflect-name*="icon-eur-png"] {
    background-image: url(../assets/imgs/icon-eur-white.png);
  }
  &[name*="icon-Free-ATM-Withdrawal"],
  &[ng-reflect-name*="icon-Free-ATM-Withdrawal"] {
    background-image: url(../assets/imgs/Free-ATM-Withdrawal.svg);
  }
  /*&[name*="icon-day-limits"],
  &[ng-reflect-name*="icon-day-limits"] {
    background-image: url(../assets/imgs/day-limits.svg);
  }*/
  &[name*="icon-month-limits"],
  &[ng-reflect-name*="icon-month-limits"] {
    background-image: url(../assets/imgs/month-limits.svg);
  }
  &[name*="icon-disable-limits-temporarily"],
  &[ng-reflect-name*="icon-disable-limits-temporarily"] {
    background-image: url(../assets/imgs/disable-limits-temporarily.svg);
  }
  &[name*="icon-print"],
  &[ng-reflect-name*="icon-print"] {
    background-image: url(../assets/imgs/print.svg);
  }
  &[name*="icon-print-active"],
  &[ng-reflect-name*="icon-print-active"] {
    background-image: url(../assets/imgs/print-active.svg);
  }
  //&[name*="icon-filter"],
  //&[ng-reflect-name*="icon-filter"] {
  //  background-image: url(../assets/imgs/filter.svg);
  //}
  //&[name*="icon-filter-active"],
  //&[ng-reflect-name*="icon-filter-active"] {
  //  background-image: url(../assets/imgs/filter-active.svg);
  //}

  //&[name*="icon-history-income"],
  //&[ng-reflect-name*="icon-history-income"] {
  //  background-image: url(../assets/imgs/history-income.svg);
  //}
  &[name*="icon-history-refund"],
  &[ng-reflect-name*="icon-history-refund"] {
    background-image: url(../assets/imgs/history-refund.svg);
  }
  &[name*="icon-history-gas"],
  &[ng-reflect-name*="icon-history-gas"] {
    background-image: url(../assets/imgs/history-gas.svg);
  }
  //&[name*="icon-history-transfer"],
  //&[ng-reflect-name*="icon-history-transfer"] {
  //  background-image: url(../assets/imgs/history-transfer.svg);
  //}
  &[name*="icon-history-exchange"],
  &[ng-reflect-name*="icon-history-exchange"] {
    background-image: url(../assets/imgs/history-exchange.svg);
  }
  //&[name*="icon-sign-out"],
  //&[ng-reflect-name*="icon-sign-out"] {
  //  background-image: url(../assets/imgs/sign-out.svg);
  //}

  &[name*="icon-identity-status"],
  &[ng-reflect-name*="icon-identity-status"] {
    background-image: url(../assets/imgs/identity-status.svg);
  }
  //&[name*="icon-my-tariff"],
  //&[ng-reflect-name*="icon-imy-tariff"] {
  //  background-image: url(../assets/imgs/my-tariff.svg);
  //}
  &[name*="icon-change-application-pin"],
  &[ng-reflect-name*="icon-change-application-pin"] {
    background-image: url(../assets/imgs/change-application-pin.svg);
  }

  &[name*="icon-protection"],
  &[ng-reflect-name*="icon-protection"] {
    background-image: url(../assets/imgs/protection.svg);
  }
  &[name*="icon-legal-notices"],
  &[ng-reflect-name*="icon-legal-notices"] {
    background-image: url(../assets/imgs/legal-notices.svg);
  }
  &[name*="icon-third-part"],
  &[ng-reflect-name*="icon-third-part"] {
    background-image: url(../assets/imgs/third-part.svg);
  }

  &[name*="icon-blocked"],
  &[ng-reflect-name*="icon-blocked"] {
    background-image: url(../assets/imgs/blocked.svg);
  }
  //&[name*="icon-block-card-alert"],
  //&[ng-reflect-name*="icon-block-card-alert"] {
  //  background-image: url(../assets/imgs/block-card-alert.svg);
  //}
  //&[name*="icon-unblock-card-alert"],
  //&[ng-reflect-name*="icon-unblock-card-alert"] {
  //  background-image: url(../assets/imgs/unblock-card-alert.svg);
  //}
  //&[name*="icon-disable-limits-alert"],
  //&[ng-reflect-name*="icon-disable-limits-alert"] {
  //  background-image: url(../assets/imgs/disable-limits-alert.svg);
  //}
  //&[name*="icon-detail-card-alert"],
  //&[ng-reflect-name*="icon-detail-card-alert"] {
  //  background-image: url(../assets/imgs/detail-card-alert.svg);
  //}
  //&[name*="icon-up-price"],
  //&[ng-reflect-name*="icon-up-price"] {
  //  background-image: url(../assets/imgs/up-price.svg);
  //}
  &[name*="icon-enable"],
  &[ng-reflect-name*="icon-enable"] {
    background-image: url(../assets/imgs/enable.svg);
  }
  &[name*="icon-read"],
  &[ng-reflect-name*="icon-read"] {
    background-image: url(../assets/imgs/icon-read.svg);
  }
  &[name*="icon-heading-off"],
  &[ng-reflect-name*="icon-heading-off"] {
    background-image: url(../assets/imgs/icon-heading-off.svg);
  }
  &[name*="icon-sent"],
  &[ng-reflect-name*="icon-sent"] {
    background-image: url(../assets/imgs/icon-sent.svg);
  }
  &[name*="icon-anonymously"],
  &[ng-reflect-name*="icon-anonymously"] {
    background-image: url(../assets/imgs/support-anonymously-bank-avatar.svg);
  }
  &[name*="icon-file-in-chat"],
  &[ng-reflect-name*="icon-file-in-chat"] {
    background-image: url(../assets/imgs/file-in-chat.svg);
  }
  &[name*="icon-file-in-chat-white"],
  &[ng-reflect-name*="icon-file-in-chat-white"] {
    background-image: url(../assets/imgs/file-in-chat-white.svg);
  }

  &[name*="icon-crypto-wallet"],
  &[ng-reflect-name*="icon-crypto-wallet"] {
    background-image: url(../assets/imgs/crypto-wallet.svg);
  }
  &[name*="icon-crypto-wallet-large"],
  &[ng-reflect-name*="icon-crypto-wallet-large"] {
    width: 82px; height: 82px;
    background-image: url(../assets/imgs/crypto-wallet-large.svg);
  }
  &[name*="icon-crypto-dash"],
  &[ng-reflect-name*="icon-crypto-dash"] {
    background-image: url(../assets/imgs/crypto-dash.svg);
  }
  &[name*="icon-crypto-etherum"],
  &[ng-reflect-name*="icon-crypto-etherum"] {
    background-image: url(../assets/imgs/crypto-etherum.svg);
  }
  &[name*="icon-crypto-monero"],
  &[ng-reflect-name*="icon-crypto-monero"] {
    background-image: url(../assets/imgs/crypto-monero.svg);
  }
  &[name*="icon-crypto-litcoin"],
  &[ng-reflect-name*="icon-crypto-litcoin"] {
    background-image: url(../assets/imgs/crypto-litcoin.svg);
  }
  &[name*="icon-send"],
  &[ng-reflect-name*="icon-send"] {
    background-image: url(../assets/imgs/icon-send.svg);
  }
  &[name*="icon-deposit"],
  &[ng-reflect-name*="icon-deposit"] {
    background-image: url(../assets/imgs/icon-deposit.svg);
  }
  &[name*="icon-receive"],
  &[ng-reflect-name*="icon-receive"] {
    background-image: url(../assets/imgs/icon-receive.svg);
  }
  &[name*="icon-receive-green"],
  &[ng-reflect-name*="icon-receive-green"] {
    background-image: url(../assets/imgs/icon-receive-green.svg);
  }
  &[name*="icon-repo"],
  &[ng-reflect-name*="icon-repo"] {
    background-image: url(../assets/imgs/icon-repo.svg);
  }

  &[name*="icon-exclamation-mark"],
  &[ng-reflect-name*="icon-exclamation-mark"] {
    background-image: url(../assets/imgs/exclamation-mark.svg);
  }
  &[name*="icon-map"],
  &[ng-reflect-name*="icon-map"] {
    background-image: url(../assets/imgs/icon-map.svg);
  }

  //&[name*="icon-receipt"],
  //&[ng-reflect-name*="icon-receipt"] {
  //  background-image: url(../assets/imgs/receipt.svg);
  //}
  &[name*="icon-receipt-green"],
  &[ng-reflect-name*="icon-receipt-green"] {
    background-image: url(../assets/imgs/receipt-green.svg);
  }
  //&[name*="icon-star"],
  //&[ng-reflect-name*="icon-star"] {
  //  background-image: url(../assets/imgs/star.svg);
  //}
  //&[name*="icon-star-black"],
  //&[ng-reflect-name*="icon-star-black"] {
  //  background-image: url(../assets/imgs/star-black.svg);
  //}
  //&[name*="icon-star-dark"],
  //&[ng-reflect-name*="icon-star-dark"] {
  //  background-image: url(../assets/imgs/star-dark.svg);
  //}

  //&[name*="icon-check"],
  //&[ng-reflect-name*="icon-check"] {
  //  background-image: url(../assets/imgs/icon-check.svg);
  //}

/*  &[name*="icon-menu-BankTransfer"],
  &[ng-reflect-name*="icon-menu-BankTransfer"] {
    background-image: url(../assets/imgs/menu-BankTransfer.svg);
    .active & { background-image: url(../assets/imgs/menu-BankTransfer-green.svg); }
  }
  &[name*="icon-menu-CardToCardTransfer"],
  &[ng-reflect-name*="icon-menu-CardToCardTransfer"] {
    background-image: url(../assets/imgs/menu-CardToCardTransfer.svg);
    .active & { background-image: url(../assets/imgs/menu-CardToCardTransfer-green.svg); }
  }
  &[name*="icon-menu-CryptoService"],
  &[ng-reflect-name*="icon-menu-CryptoService"] {
    background-image: url(../assets/imgs/menu-CryptoService.svg);
    .active & { background-image: url(../assets/imgs/menu-CryptoService-green.svg); }
  }
  &[name*="icon-menu-CurrencyExchange"],
  &[ng-reflect-name*="icon-menu-CurrencyExchange"] {
    background-image: url(../assets/imgs/menu-CurrencyExchange.svg);
    .active & { background-image: url(../assets/imgs/menu-CurrencyExchange-green.svg); }
  }
  &[name*="icon-menu-Money"],
  &[ng-reflect-name*="icon-menu-Money"] {
    background-image: url(../assets/imgs/menu-Money.svg);
    .active & { background-image: url(../assets/imgs/menu-Money-green.svg); }
  }
  &[name*="icon-menu-SendMoneyRequest"],
  &[ng-reflect-name*="icon-menu-SendMoneyRequest"] {
    background-position: 17% 50%;
    background-image: url(../assets/imgs/menu-SendMoneyRequest.svg);
    .active & { background-image: url(../assets/imgs/menu-SendMoneyRequest-green.svg); }
  }
  &[name*="icon-menu-Settings"],
  &[ng-reflect-name*="icon-menu-Settings"] {
    background-image: url(../assets/imgs/menu-Settings.svg);
    .active & { background-image: url(../assets/imgs/menu-Settings-green.svg); }
  }
  &[name*="icon-menu-Support"],
  &[ng-reflect-name*="icon-menu-Support"] {
    background-image: url(../assets/imgs/menu-Support.svg);
    .active & { background-image: url(../assets/imgs/menu-Support-green.svg); }
  }*/
  //&[name*="icon-menu-TransferToContact"],
  //&[ng-reflect-name*="icon-menu-TransferToContact"] {
  //  background-image: url(../assets/imgs/menu-TransferToContact.svg);
  //  .active & { background-image: url(../assets/imgs/menu-TransferToContact-green.svg); }
  //}


  //&[name*="icon-repeat"],
  //&[ng-reflect-name*="icon-repeat"] {
  //  background-image: url(../assets/imgs/repeat.svg);
  //}
  //&[name*="icon-repeat-new"],
  //&[ng-reflect-name*="icon-repeat-new"] {
  //  background-image: url(../assets/imgs/repeat-new.svg);
  //}
  &[name*="icon-full"],
  &[ng-reflect-name*="icon-full"] {
    background-image: url(../assets/imgs/icon-full.svg);
  }


  &[name*="icon-country--germany"],
  &[ng-reflect-name*="icon-country--germany"] {
    background-image: url(../assets/imgs/icon-country--germany.png);
  }


  //&[name*="icon-map-link"],
  //&[ng-reflect-name*="icon-map-link"] {
  //  background-image: url(../assets/imgs/icon-link.svg);
  //}
  //&[name*="icon-map-phone"],
  //&[ng-reflect-name*="icon-map-phone"] {
  //  background-image: url(../assets/imgs/icon-phone.svg);
  //}
  //&[name*="icon-map-pin"],
  //&[ng-reflect-name*="icon-map-pin"] {
  //  background-image: url(../assets/imgs/icon-pin.svg);
  //}
  //&[name*="icon-map-time"],
  //&[ng-reflect-name*="icon-map-time"] {
  //  background-image: url(../assets/imgs/icon-time.svg);
  //}
  &[name*="icon-plus-green"],
  &[ng-reflect-name*="icon-plus-green"] {
    background-image: url(../assets/imgs/icon-plus-green.svg);
  }
  //&[name*="icon-plus-dark"],
  //&[ng-reflect-name*="icon-plus-dark"] {
  //  background-image: url(../assets/imgs/icon-plus-dark.svg);
  //}


  //&[name*="icon-succeess"],
  //&[ng-reflect-name*="icon-succeess"] {
  //  background-image: url(../assets/imgs/icon-succeess.svg);
  //}
  &[name*="icon-inactive"],
  &[ng-reflect-name*="icon-inactive"] {
    background-image: url(../assets/imgs/icon-inactive.svg);
  }


  &[name*="icon-settings-off-on"],
  &[ng-reflect-name*="icon-settings-off-on"] {
    background-image: url(../assets/imgs/icon-settings-off-on.svg);
  }
  &[name*="icon-settings-qr"],
  &[ng-reflect-name*="icon-settings-qr"] {
    background-image: url(../assets/imgs/icon-settings-qr.svg);
  }
  &[name*="icon-settings-qr-green"],
  &[ng-reflect-name*="icon-settings-qr-green"] {
    background-image: url(../assets/imgs/icon-settings-qr-green.svg);
  }
  &[name*="icon-settings-notifications"],
  &[ng-reflect-name*="icon-settings-notifications"] {
    background-image: url(../assets/imgs/icon-settings-notifications.svg);
  }


  &[name*="icon-with-draw"],
  &[ng-reflect-name*="icon-with-draw"] {
    background-image: url(../assets/imgs/with-draw.svg);
  }
  &[name*="icon-pen"],
  &[ng-reflect-name*="icon-pen"] {
    background-image: url(../assets/imgs/icon-pen.svg);
  }
  //&[name*="icon-fail"],
  //&[ng-reflect-name*="icon-fail"] {
  //  background-image: url(../assets/imgs/icon-Fail.svg);
  //}


  //&[name*="icon-warning"],
  //&[ng-reflect-name*="icon-warning"] {
  //  background-image: url(../assets/imgs/icon-warning.svg);
  //}

  //&[name*="icon-arrow-down-long-green"],
  //&[ng-reflect-name*="icon-arrow-down-long-green"] {
  //  background-image: url(../assets/imgs/arrow-down-long-green.svg);
  //}


  //&[name*="icon-datetime"],
  //&[ng-reflect-name*="icon-datetime"] {
  //  background-image: url(../assets/imgs/icon-datetime.svg);
  //}

  &[name*="message-error"],
  &[ng-reflect-name*="message-error"] {
    background-image: url(../assets/imgs/error.svg);
  }


  &[name*="icon-gbp"],
  &[ng-reflect-name*="icon-gbp"] {
    background-image: url(../assets/imgs/gbp.svg);
  }
  &[name*="icon-rub"],
  &[ng-reflect-name*="icon-rub"] {
    background-image: url(../assets/imgs/rub.svg);
  }
  &[name*="icon-usd"],
  &[ng-reflect-name*="icon-usd"] {
    background-image: url(../assets/imgs/usd.svg);
  }
  &[name*="link"],
  &[ng-reflect-name*="link"] {
    background-image: url(../assets/custom-icons/link.svg);
  }
}
