ion-searchbar,
.sc-ion-searchbar-md-h,
.sc-ion-searchbar-ios-h {
  padding: 0;
  margin-bottom: 19px;
  
  --background: rgba(230, 235, 239, 0.4);
  --color-icon: transparent;
  --color: var(--ion-color-black);
  --button-color-clear: transparent;
  --placeholder-color: var(--ion-color-medium);
  --placeholder-font-style: normal;
  --placeholder-font-weight: 400;
  --placeholder-opacity: 1;

  @media (min-width: 1280px) {
    margin-bottom: 14px;
  }

  .searchbar-input.sc-ion-searchbar-md,
  .searchbar-input.sc-ion-searchbar-ios {
    height: 32px;
    padding-left: 2.1875rem;
    font-size: 0.875rem;
    letter-spacing: -0.4px;
    box-shadow: none;
    border-radius: 0.625rem;

    @media (min-width: 1280px) {
      height: 2.25rem;
    }
  }
  .searchbar-search-icon.sc-ion-searchbar-md,
  .searchbar-search-icon.sc-ion-searchbar-ios {
    top: 50%;
    left: 6px;
    width: 1.75rem;
    height: 1.75rem;
    margin-top: -16px;
    background-image: url(/assets/imgs/search.svg);
    background-size: 1.5625rem auto;
    background-position: center;
    --icon-color: transparent;
    
    @media (min-width: 1280px) {
      left: .5rem;
      margin-top: -0.875rem;
    }
  }
  .searchbar-clear-button.sc-ion-searchbar-md,
  .searchbar-clear-button.sc-ion-searchbar-ios {
    right: 2px;
    width: 1.75rem;
    height: 32px;
    background-image: url(/assets/imgs/close-round.svg);
    background-repeat: no-repeat;
    background-size: 0.875rem auto;
    background-position: center;
    @media (min-width: 1280px) {
      right: 0.375rem;
      height: 100%;
    }

    .searchbar-clear-icon.sc-ion-searchbar-md,
    .searchbar-clear-icon.sc-ion-searchbar-ios {
      display: none;
    }
  }
  &.searchbar-has-value {
    .searchbar-clear-button.sc-ion-searchbar-md,
    .searchbar-clear-button.sc-ion-searchbar-ios {
      display: block;
    }
  }
}
.sc-ion-searchbar-ios-h {
  height: 36px;
  padding: 0 10px 0 8px;
  @media (min-width: 1280px) {
    height: 60px;
    padding: 0;
  }
 &.searchbar-left-aligned {
  .searchbar-input.sc-ion-searchbar-ios {
    -webkit-padding-start: 35px;
    padding-inline-start: 35px;

    @media (min-width: 1280px) {
      -webkit-padding-start: 30px;
      padding-inline-start: 30px;
    }
  }
 }
}